import React from 'react'
import SectionTab from './SectionTab'

interface sectionThreeProps {
    description: string
    heading: string
    icon: string
    img: any
}
const SectionThree: React.FC<sectionThreeProps> = ({ description, heading, icon, img }) => {
    return (
       <>
         <div style={{ backgroundColor: '#F2F5FA' }} className="py-md-3 animate__animated animate__backInRight  animate__delay-.5s 
        d-md-block d-none" data-aos="flip-up">
            <div className="container ps-3 ps-md-1 pe-md-4 pe-4 mb-md-none pb-4 pb-md-none ">
                <div className="row align-items-center p-md-5 ">
                    <div className="col-12 col-md-6">
                        <SectionTab icon={icon} heading={heading} description={description} />
                    </div>
                    <div className="col-12 col-md-6  justify-content-end align-items-end">
                        <img className="w-100" src={img} />
                    </div>
                </div>
            </div>
        </div>
        <div className="d-md-none d-block">
            <div className="container ps-3 ps-md-1 pe-md-4 pe-4 mb-md-none pb-4 pb-md-none ">
                <div className="row align-items-center p-md-5 ">
                    <div className="col-12 col-md-6  justify-content-end align-items-end">
                        <img className="img-fluid" src={img} />
                    </div>
                    <div className="col-12 col-md-6">
                        <SectionTab icon={icon} heading={heading} description={description} />
                    </div>
                </div>
            </div>
        </div>
       </>
    )
}

export default SectionThree
