import React from 'react'
import { COLORS } from '../../resources/colors-contants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface RegularBtnProps {
    btnAction?: () => void
    text?: string
    width?: string
    icon?: any
    backgroundColor?: string
    color?: string
    padding?: string
    py?: string
    borderRadius?: string
    regPad?: string
    blurry?: string
    block?: string
    borderCol?: string
    btnFontSize?: string
    btnFontWeight?: string
    btnHeight?: string
}

const RegularBtn: React.FC<RegularBtnProps> = ({
    btnAction,
    text,
    width,
    icon,
    backgroundColor,
    color,
    padding,
    py,
    borderRadius,
    regPad,
    blurry,
    block,
    borderCol,
    btnFontSize,
    btnFontWeight,
    btnHeight
}) => {
    return (
        <button
            className={`${regPad ? regPad : 'py-3'} ${block ? block : ''} btn`}
            style={{
                backgroundColor: backgroundColor,
                color: color,
                fontWeight: btnFontWeight ? btnFontWeight : '500',
                fontSize: btnFontSize ? btnFontSize : '16px',
                borderColor: borderCol ? borderCol : COLORS.primary_background,
                padding: padding,
                paddingTop: py ? py : '',
                paddingBottom: py ? py : '',
                width: width,
                borderRadius,
                backdropFilter: blurry ? blurry : '',
                height: btnHeight ? btnHeight : '48px'
            }}
            onClick={btnAction}
        >
            {icon && (
                <span className={icon !== '' ? 'me-3' : ''} style={{ fontWeight: '500' }}>
                    <FontAwesomeIcon icon={icon} />
                </span>
            )}
            {text}
        </button>
    )
}

export default RegularBtn
