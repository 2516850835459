import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { getRequest } from '../../../resources/api-request'
import moment from 'moment'
import { LuDownload } from 'react-icons/lu'
import { NavLink } from 'react-router-dom'

declare global {
    interface Window {
        MSStream: any
    }
}

interface DeviceOSState {
    osName: 'Android' | 'iOS' | 'Unknown'
}
type VersionType = {
    version: string
    description: string
    date: string // Ensure this is in a format that can be parsed by Date, like "YYYY-MM-DD"
    arch: { size: string; bit: string; link: string }[]
}
const DownloadMobileView = () => {
    const [deviceOS, setDeviceOS] = useState<DeviceOSState>({ osName: 'Unknown' })
    const [activeTab, setActiveTab] = useState<null | number>(null)
    const { isLoading, isError, data } = useQuery<any>('result', () => getRequest('https://api.klakpad.com/download_process.php'))

    if (isLoading) {
        return (
            <>
                <div className="row align-items-center justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </>
        )
    }
    if (isError) {
        console.log(isError)

        return (
            <>
                {' '}
                <div>Encountered an error</div>
            </>
        )
    }

    const handleDisplayContent = (tab: number) => {
        setActiveTab(activeTab === tab ? null : tab)
    }

    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor

        // Detect Android
        if (/android/i.test(userAgent)) {
            setDeviceOS({ osName: 'Android' })
        }
        // Detect iOS
        else if (/iPad|iPhone|iPod/.test(userAgent)) {
            setDeviceOS({ osName: 'iOS' })
        }
        // Default to Unknown if neither
        else {
            setDeviceOS({ osName: 'Unknown' })
        }
    }, [])
    const sortedWindowsVersions = (data?.data?.Windows ?? []).sort((a: VersionType, b: VersionType) => {
        // Parse dates and compare them
        return new Date(b.date).getTime() - new Date(a.date).getTime()
    })
    const sortedMacVersions = (data?.data?.Mac ?? []).sort((a: VersionType, b: VersionType) => {
        // Parse dates and compare them
        return new Date(b.date).getTime() - new Date(a.date).getTime()
    })
    const sortedLinuxVersions = (data?.data?.Linux ?? []).sort((a: VersionType, b: VersionType) => {
        // Parse dates and compare them
        return new Date(b.date).getTime() - new Date(a.date).getTime()
    })
    const { osName } = deviceOS
    return (
        <>
            <div className="row d-md-none d-block justify-content-center ">
                <div className="d-flex align-items-center justify-content-center pb-5 mb-5">
                    {osName === 'Android' && (
                        <>
                            <NavLink to="https://play.google.com/store/apps/details?id=com.klakpad.klakpad" target='_blank' >
                                <img src="/assets/playstore.svg" className="img-fluid" alt="" />
                            </NavLink>
                        </>
                    )}
                    {osName === 'iOS' && (
                        <> 
                            <NavLink to="https://apps.apple.com/us/app/klakpad-sella/id6504258004" target='_blank' >
                                <img src="/assets/appstore.svg" className="img-fluid" alt="" />
                            </NavLink>
                        </>
                    )}
                </div>
                <p className="text-center pb-3">Klakpad app is also available on:</p>
                <div className="row ">
                    <button onClick={() => handleDisplayContent(1)} className={`nav-link downloads--link mx-3 ${activeTab === 1 || activeTab === null? 'downloads--active' : ''}`}>
                        <i className="fa-brands fa-windows mx-2" /> Windows
                    </button>
                    {activeTab === 1 && (
                        <>
                           {sortedWindowsVersions.length === 0 && (
                            <h5 className='text-center'>No available downloads yet.</h5>
                           )}
                            {sortedWindowsVersions.map((version: VersionType, index: number) => {
                                return (
                                    <div key={index} className="mt-4 border-bottom mb-4 justify-content-center ms-2">
                                        <div className="downloads__table--version">{version.version}</div>
                                        <p className="downloads__table--description ">{version.description}</p>
                                        <div className="row pb-3">
                                            <div className="col-10">
                                                <small className="text-secondary pe-2 downloads__table--text">Date released:</small>
                                                <span className="downloads__table--text">{moment(version.date).format('MMMM Do, YYYY')}</span>
                                            </div>
                                            <div className="col-2 text-end">
                                                <span className="downloads__table--text">
                                                    {' '}
                                                    {version.arch[0].size ? version.arch[0].size + 'MB' : version.arch[1].size + 'MB'}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="dropdown selectdiv fw-medium mb-3 pb-3 align-items-center">
                                            <button className="" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <LuDownload className="mx-1" /> Download
                                            </button>
                                            <ul className="dropdown-menu ">
                                                {version.arch.map((arch: any, index: number) => (
                                                    <li key={index}>
                                                        <a className="dropdown-item" href={arch?.link} target="_blank" rel="noreferrer">
                                                            Download ({arch?.bit})bit
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                    )}
                </div>
                <div className="row">
                    <button className={`nav-link downloads--link mx-3 ${activeTab === 2 ? 'downloads--active' : ''}`} onClick={() => handleDisplayContent(2)}>
                        {' '}
                        <i className="fa-brands fa-apple mx-2" />
                        Mac Os
                    </button>
                    {activeTab === 2 && (
                        <>
                           {sortedMacVersions.length === 0 && (
                            <h5 className='text-center'>No available downloads yet.</h5>
                           )}
                            {sortedMacVersions.map((version: VersionType, index: number) => {
                                return (
                                    <div key={index} className="mt-4 border-bottom mb-4 justify-content-center ms-2">
                                        <div className="downloads__table--version">{version.version}</div>
                                        <p className="downloads__table--description ">{version.description}</p>
                                        <div className="row pb-3">
                                            <div className="col-10">
                                                <small className="text-secondary pe-2 downloads__table--text">Date released:</small>
                                                <span className="downloads__table--text">{moment(version.date).format('MMMM Do, YYYY')}</span>
                                            </div>
                                            <div className="col-2 text-end">
                                                <span className="downloads__table--text">
                                                    {' '}
                                                    {version.arch[0].size ? version.arch[0].size + 'MB' : version.arch[1].size + 'MB'}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="dropdown selectdiv fw-medium mb-3 pb-3 align-items-center">
                                            <button className="" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                 <LuDownload className="mx-1" /> Download
                                            </button>
                                            <ul className="dropdown-menu ">
                                                {version.arch.map((arch: any, index: number) => (
                                                    <li key={index}>
                                                        <a className="dropdown-item" href={arch?.link} target="_blank" rel="noreferrer">
                                                            Download ({arch?.bit})bit
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                    )}
                </div>
                <div className="row">
                    <button
                        id="linux"
                        className={`nav-link downloads--link mx-3 ${activeTab === 3 ? 'downloads--active' : ''}`}
                        onClick={() => handleDisplayContent(3)}
                    >
                        {' '}
                        <i className="fa-brands fa-linux mx-2" />
                        Linux
                    </button>
                    {activeTab === 3 && (
                        <>
                           {sortedLinuxVersions.length === 0 && (
                            <h5 className='text-center'>No available downloads yet</h5>
                           )}
                            {sortedLinuxVersions.map((version: VersionType, index: number) => {
                                return (
                                    <div key={index} className="mt-4 border-bottom mb-4 justify-content-center ms-2" id="#linux">
                                        <div className="downloads__table--version">{version.version}</div>
                                        <p className="downloads__table--description ">{version.description}</p>
                                        <div className="row pb-3">
                                            <div className="col-10">
                                                <small className="text-secondary pe-2 downloads__table--text">Date released:</small>
                                                <span className="downloads__table--text">{moment(version.date).format('MMMM Do, YYYY')}</span>
                                            </div>
                                            <div className="col-2 text-end">
                                                <span className="downloads__table--text">
                                                    {' '}
                                                    {version.arch[0].size ? version.arch[0].size + 'MB' : version.arch[1].size + 'MB'}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="dropdown selectdiv fw-medium mb-3 pb-3 align-items-center">
                                            <button className=" " type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                 <LuDownload className="mx-1" /> Download
                                            </button>
                                            <ul className="dropdown-menu ">
                                                {version.arch.map((arch: any, index: number) => (
                                                    <li key={index}>
                                                        <a className="dropdown-item" href={arch?.link} target="_blank" rel="noreferrer">
                                                            Download ({arch?.bit})bit
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                    )}
                </div>
            </div>
        </>
    )
}
export default DownloadMobileView
