import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { getRequest } from '../../../resources/api-request';

// Define the shape of your context data
const MyContext = createContext();

// Create a custom hook to use the context
export const useMyContext = () => {
  const context = useContext(MyContext);
  if (!context) {
    throw new Error('useMyContext must be used within a MyContextProvider');
  }
  return context;
};

// Create the context provider component
export const MyContextProvider = ({ children }) => {
  const [myData, setMyData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getRequest('subscription-plans');
        setMyData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <MyContext.Provider value={{ myData, loading }}>
      {loading ? <p className='text-center'>Loading...</p> : children}
    </MyContext.Provider>
  );
};

// Add prop validation for children
MyContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
