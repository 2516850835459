import React from 'react'
import { COLORS } from '../../../../resources/colors-contants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleDot } from '@fortawesome/free-solid-svg-icons'
import './styles/bullet.css'

interface bulletInterface {
    head: string
    body: string
}
const BulletSection: React.FC<bulletInterface> = ({ head, body }) => {
    const containStyle = {
        marginBottom: '2rem'
    }
    const headStyle = { fontWeight: '500', color: COLORS.product_headings }
    const bodyStyle = { fontWeight: '400', fontSize: '16px', lineHeight: '25.6px', color: COLORS.product_paragraph }
    return (
        <div style={containStyle}>
            <div className="d-flex">
                <FontAwesomeIcon id="bulletItself" className="me-3 mt-2 mt-md-3" icon={faCircleDot} />
                <div>
                    <h3 style={headStyle} id="bulletHeadStyle">
                        {head}
                    </h3>
                    <p style={bodyStyle}>{body}</p>
                </div>
            </div>
        </div>
    )
}

export default BulletSection
