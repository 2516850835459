import React from 'react'
import PayWithWallet from './payWithWallet'
import { planDetailsStates } from './planDetailsStates'

const PlanpaymentType = ({
    name,
    selected,
    id,
    handlePaymentType,
    handlePayment,
    description,
    formattedAmount,
    plan_id,
    plan_duration,
    planDetails,
    paymentGateway,
    handleRadioChange,
    paymentMethodChoices,
    icon
}: any) => {

    const {payment_method} = planDetailsStates();
    // console.log({payment_method});

    return (
        <>
            <div className={`d-flex flex-row`}>
                <div
                    className="flex-grow-1"
                    // data-bs-target={`${name === 'wallet' ? '#exampleModal' : ''}`}
                    // data-bs-toggle={`${name === 'wallet' ? 'modal' : ''}`}
                >
                  {icon}  {description}
                </div>
                <div>
                    <input
                        // data-bs-target={`${name === 'wallet' ? '#exampleModal' : ''}`}
                        // data-bs-toggle={`${name === 'wallet' ? 'modal' : ''}`}
                        // className=""
                        type="radio"
                        name="redis"
                        onClick={() => handlePayment(name)}
                        onChange={(e) => handlePaymentType(e, id)}
                        id={id}
                        checked={payment_method == name ? true : false}
                    />
                </div>
            </div>
            <PayWithWallet
                formattedAmount={formattedAmount}
                plan_id={plan_id}
                plan_duration={plan_duration}
                planDetails={planDetails}
                paymentGateway={paymentGateway}
                handleRadioChange={handleRadioChange}
                paymentMethodChoices={paymentMethodChoices}
            />
        </>
    )
}
export default PlanpaymentType
