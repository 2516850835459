import { paystackCharges } from "./paystackCharges";

export const calculateCharges = (amount: any, currency:any='NGN', chargeType:any='local') => {

    const charges:any = paystackCharges;
    
    const percentage = charges[currency][chargeType].percentage;
    const fixed = charges[currency][chargeType].fixed;
    const cap = charges[currency][chargeType].cap || Infinity;
    const waiver = charges[currency][chargeType].waiver || 0;
  
    let totalCharge = (percentage / 100) * amount + fixed;
    if (amount < waiver) {
      totalCharge = (percentage / 100) * amount;
    }
    return Math.min(totalCharge, cap);
};  