import Cookies from 'js-cookie'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
// import { postRequest } from '../../../utility/apiRequest'
import { useLocation, useNavigate } from 'react-router-dom'
import { postRequest } from '../../utility/apiRequest'

const Opt: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [response, setResponse] = useState('')
    const navigate = useNavigate()
    const location = useLocation()
    const length = 5
    const [otp, setOtp] = useState(new Array(length).fill(''))
    const inputsRef = useRef<(HTMLInputElement | null)[]>([])
    const [newOtpNumber, setNewOtpNumber] = useState<string>('')
    const [userResponseType, setUserResponseType] = useState<boolean>(false)

    const handleChange = (element: ChangeEvent<HTMLInputElement>, index: number) => {
        const value = element.target.value
        // Allow only numbers
        if (/^[0-9]$/.test(value) || value === '') {
            const newOtp = [...otp]
            newOtp[index] = value
            setOtp(newOtp)

            // Move to next input if not the last input and current input is filled
            if (value !== '' && index < length - 1) {
                inputsRef.current[index + 1]?.focus()
            }

            // Check if all inputs are filled
            if (newOtp.every((num) => num !== '')) {
                handleComplete(newOtp.join(''))
            }
        }
    }

    const handleFocus = (index: number) => {
        const current = inputsRef.current[index]
        current?.focus()
        current?.select()
    }

    useEffect(() => {
        inputsRef.current[0]?.focus()
    }, [])

    const handleComplete = (otp: string) => {
        setNewOtpNumber(otp)
        // Here you can manage what happens when the OTP is completed
        // For example, verify the OTP against a backend service
    }
    const handleOpt = async () => {
        setLoading(true)
        const storedUserData: any = Cookies.get('userData')
        if (!storedUserData) return
        const parsedUserData: any = JSON.parse(storedUserData)

        const email = parsedUserData?.email
        const phone = parsedUserData?.phone
        const result = await postRequest('validate-account-otp', { email, phone, function_type: 'activate-check', code: newOtpNumber })
        console.log(result.data)

        if (result.data.success === true) {
            setTimeout(() => {
                setResponse(result.data.message)
                navigate('/set-newpassword')
            }, 3000)
        }

        if (result.data.success === false) {
            setLoading(false)
            setUserResponseType(false)
            setResponse(result.data.message)
            setTimeout(() => {
                setResponse('')
            }, 6000)
            setResponse(result.data.message)
        }
    }

    const handleResendOpt = async () => {
        const storedUserData: any = Cookies.get('userData')
        const parsedUserData: any = JSON.parse(storedUserData)
        console.log(parsedUserData)
        const email = parsedUserData?.email
        const phone = parsedUserData?.phone
        const result = await postRequest('validate-account-otp', { email, phone, function_type: 'activate-resend', code: '0' })
        console.log(result)

        if (result.data.success === true) {
            setResponse(result.data.message + ' :please check your phone')
        } else {
            setResponse(result.data.message)
        }
        if (result.data.success === false) {
            setLoading(false)
            setUserResponseType(false)
            setResponse(result.data.message)
            setTimeout(() => {
                setResponse('')
            }, 6000)
            setResponse(result.data.message)
        }
    }

    return (
        <>
            <div className="verification py-5">
                <div className="container py-5">
                    <div className="row justify-content-center align-items-center text-center py-5">
                        <div className="col">
                            <img src="/assets/phone.png" alt="logo" className="img-fluid mb-4" width={'60px'} />
                        </div>
                        <h3 className="signup__col--title">Password reset</h3>
                        <p className="pb-5 mb-5 signup__col--text">We sent a 5 digit code to email please enter it below.</p>
                        <div className="col-md-8  mb-3">
                            {response && <p className={userResponseType ? 'alert alert-success' : 'alert alert-danger'}>{response}</p>}
                        </div>
                        <div className="col-md-10 ">
                            <div className=" text-center row justify-content-center">
                                {otp.map((data, index) => (
                                    <input
                                        key={index}
                                        ref={(ref) => (inputsRef.current[index] = ref)}
                                        type="text"
                                        maxLength={1}
                                        value={data}
                                        onChange={(e) => handleChange(e, index)}
                                        onFocus={() => handleFocus(index)}
                                        style={{ width: '40px', marginRight: '5px' }}
                                        className="py-3 verification--inp form-control shadow-none mx-2"
                                    />
                                ))}
                            </div>
                        </div>
                        <p className="pt-4 pb-5 signup__col--label">
                            Didn’t receive any code?{' '}
                            <b onClick={handleResendOpt} style={{ cursor: 'pointer' }} className='signup__col--link'>
                                {' '}
                                Resend new code
                            </b>
                        </p>
                        <div className="col-md-5">
                            <button className="signup__col--btn" onClick={handleOpt}>
                                {loading ? (
                                    <div className="spinner-border spinner-border-sm" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                ) : (
                                    <span> Verify phone number</span>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Opt
