import React from 'react'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { COLORS } from '../../../resources/colors-contants'
import RegularBtn from '../../../components/buttons/RegularBtn'
import '../styles/DownloadAppCard.css'

interface DownloadAppCardProp {
    versionId: string
    versionName: string
    link: string
    size: string
    releaseDate: string
    description: string
}

const DownloadAppCard: React.FC<DownloadAppCardProp> = ({ versionId, versionName, link, size, releaseDate, description }) => {
    const myStyle = { color: '#111A24', fontSize: '16px' }
    return (
        <div className="container" id="d-card-container">
            <div className="card p-3 mb-5 d-flex flex-column" id="card-item">
                <div className="row flex-grow-1">
                    <div className="col col-12 col-md-9 mb-3 mb-md-0">
                        <div className="">
                            <span className="me-3 me-md-5" id="beta-ver">
                                Klakpad v{versionId}
                            </span>
                            <span className="px-2 py-2" id="beta" style={{ color: '#111A24' }}>
                                {versionName}
                            </span>
                        </div>
                        <div className="mt-3" id="beta-desc">
                            {description}
                        </div>
                    </div>
                    <div className="col col-12 col-md-3 mb-md-0 d-block d-md-flex align-items-end justify-content-center mt-3 mt-md-0 mb-2 mb-md-0">
                        <RegularBtn
                            text="Download"
                            btnAction={() => console.log('clicked')}
                            width=""
                            icon={faDownload}
                            backgroundColor={COLORS.primary_background}
                            color="white"
                            py="10px"
                        />
                    </div>
                </div>
                <div className="mt-3" style={{ fontStyle: 'italic', color: '#60666D', fontSize: '14px', fontWeight: '400' }}>
                    <span className="me-5">{size}</span>
                    <span>{releaseDate}</span>
                </div>
            </div>
        </div>
    )
}

export default DownloadAppCard
