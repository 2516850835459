import React from 'react'
import './hero.css'
import RegularBtn from '../../../../components/buttons/RegularBtn'
import { COLORS } from '../../../../resources/colors-contants'
import { useNavigate } from 'react-router-dom'

const ProductHero: React.FC = () => {
    const navigate = useNavigate()

    const handleNavigate = (path: string) => {
        navigate(path)
    }
    return (
        <div id="background-image">
            <div className="container " style={{ color: COLORS.white_color }}>
                {/* <div id="content"  className=""> */}
                <div
                    id="retailStyle"
                    style={{
                        fontWeight: '400'
                    }}
                    className=""
                >
                    
                </div>
                <div>
                    <h1
                        className="mb-0 mb-md-4"
                        id="klakSellaStyle"
                        style={{
                            fontWeight: '600'
                        }}
                    >
                        Klakpad Sella
                    </h1>
                </div>
                <div id="heroDesc">
                    <p style={{ fontWeight: '400' }}>Everything you need, both offline and online, from open to shut.</p>
                </div>

                <div className="d-flex mt-4 mb-4">
                    <div className="me-3">
                        <RegularBtn
                            btnAction={() => {
                                navigate('/signup')
                            }}
                            text="Get Started"
                            width="150px"
                            backgroundColor="white"
                            color={COLORS.primary_background}
                            padding="15px"
                            py="0px"
                            borderRadius="10px"
                            borderCol={COLORS.white_color}
                        />
                    </div>
                    <div>
                        <RegularBtn
                            btnAction={() => {
                                navigate('/contact')
                            }}
                            text="Contact Sales"
                            width="170px"
                            backgroundColor="linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.6))"
                            color="white"
                            padding="15px"
                            py="0px"
                            borderRadius="10px"
                            blurry="blur(10px)"
                            borderCol={COLORS.white_color}
                        />
                    </div>
                </div>
                {/* </div> */}
            </div>
        </div>
    )
}

export default ProductHero
