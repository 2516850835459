import React from 'react'
import { COLORS } from '../../../../resources/colors-contants'
import RegularBtn from '../../../../components/buttons/RegularBtn'
import './sellaLearn.css'

const SellaLearn: React.FC = () => {
    return (
        <div>
            <div style={{ backgroundColor: COLORS.secondary_background, paddingTop: '4rem', paddingBottom: '4rem', marginTop: '5rem', marginBottom: '5rem' }}>
                <div className="container">
                    <div className="row mb-5 gx-5">
                        <div id="learnHead" className="col col-12 col-md-6">
                            <h1
                                className=" text-center text-md-start px-5 px-md-0"
                                style={{
                                    color: COLORS.product_headings,
                                    fontWeight: '600',
                                    letterSpacing: '-2%'
                                }}
                            >
                                Learn more about Klakpad sella
                            </h1>
                        </div>
                        <div className="col col-12 col-md-6"></div>
                    </div>
                    <div className="row gx-5">
                        <div
                            className="col col-12 col-md-6 mb-5 mb-md-0 text-center text-md-start"
                            style={{ color: COLORS.product_paragraph, fontSize: '18px', fontWeight: '400', lineHeight: '30.6px' }}
                        >
                            Get informed about the latest features and offers from Klakpad Sella and hear success stories from other retail establishments.
                        </div>
                        <div className="col col-12 col-md-6 d-none d-md-flex">
                            {/* <div className="row">
                                <div className="col"></div>
                                <div className="col"></div>
                            </div> */}
                            <input className="form-control me-2" placeholder="Enter your email address" />
                            <RegularBtn
                                btnAction={() => {
                                    console.log('nothing')
                                }}
                                text="Submit"
                                width="150px"
                                backgroundColor={COLORS.primary_background}
                                color={COLORS.white_color}
                                regPad="py-0"
                            />
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="d-md-none d-grid gap-3">
                        <input className="form-control form-control-lg " placeholder="Enter your email address" />
                        <RegularBtn
                            btnAction={() => {
                                console.log('nothing')
                            }}
                            text="Submit"
                            block="d-block"
                            // width="150px"
                            backgroundColor={COLORS.primary_background}
                            color={COLORS.white_color}
                            // regPad="py-3"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SellaLearn
