import React from 'react'
import TermAndCondition from './component/termsAndConditions'

const TermsAndConditions = () => {
    return (
        <div>
            <TermAndCondition />
        </div>
    )
}

export default TermsAndConditions
