/**
 * This function can be used anywhere in the app to greet the user
 * @param userName The user's first name
 * @returns A kind greeting message
 */
export const sayHello = (userName: string): string => {
    return 'Welcome ' + userName + '!'
}

export const convertToCamelCase = (phrase: string) => {
    const words = phrase.split(' ')
    const camelCaseWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    return camelCaseWords.join(' ') // Join words with spaces
}
export const format = (num: any, dec: any = 0, acr = "") => {
    return `${num.toFixed(dec).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}${acr}`;
  };