import React, { useState } from 'react'
import { FaChevronDown } from 'react-icons/fa6'
import { FaChevronUp } from 'react-icons/fa6'
import { faFacebookF, faSquareInstagram, faLinkedin, faTiktok, faXTwitter } from '@fortawesome/free-brands-svg-icons'
import { NavLink } from 'react-router-dom'
import moment from 'moment'

function FooterMobile() {
    const [displayItemContents, setDisplayItemsContent] = useState([
        {
            id: 1,
            name: 'Why Klakpad',
            description: [
                'Klakpad provides tailored solutions to handle the challenging aspects of business operations, allowing you to experience increased efficiency and the freedom to concentrate on what truly matters to you.'
            ],
            status: false
        },
        {
            id: 2,
            name: 'Product',
            description: ['Hotel management Solution', 'Restaurant and Lounge'],
            status: false
        },
        {
            id: 3,
            name: 'Company',
            description: ['About us', 'Testimonials'],
            status: false
        },
        {
            id: 4,
            name: 'Who we serve',
            description: ['Small and medium businesses.', 'See elaborated list'],
            status: false
        },
        {
            id: 5,
            name: 'Contact us',
            description: ['+234 707 148 8217 | +234 807 955 8667', 'Email: contact@Klakpad.com', 'Klakpad Global Inc, USA 913 Miller St, Easton, Pennsylvania 18042 USA', 'Klakpad Global Limited, NigeriaNo 9, Klakpad building Ire-Akari Estate, Ibadan, Oyo State, Nigeria'],
            status: false
        }
    ])
    const icons = [
        { link: 'https://www.instagram.com/klakpad/', name: faSquareInstagram, icon: '/assets/icons/icon4.svg' },
        { link: '#!', name: faFacebookF, icon: '/assets/icons/icon2.svg' },
        { link: '#!', name: faLinkedin, icon: '/assets/icons/icon3.svg' },
        { link: 'https://twitter.com/klakpad', name: faXTwitter, icon: '/assets/icons/icon.svg' },
        { link: 'https://www.tiktok.com/@klakpad?_t=8mL7UkqBLMr&_r=1', name: faTiktok, icon: '/assets/icons/icon5.svg' }
    ]
    const handleToggledItem = (id: number) => {
        const updatedItem = displayItemContents.map((val) => {
            if (val.id === id) {
                return { ...val, status: !val.status }
            }
            return val
        })
        setDisplayItemsContent(updatedItem)
    }
    const currentYear = moment().format("YYYY"); 
    return (
        <>
            <div className="footer">
                <div className="container pb-5 ps-5 pe-5">
                    <div className="row  d-block d-md-none  footer__mobile">
                        {displayItemContents.map(({ id, name, description, status }) => (
                            <>
                                <div key={id}>
                                    <div className="text-center mb-4 footer__mobile--name" onClick={() => handleToggledItem(id)}>
                                        {name} {status ? <FaChevronUp className='mx-2'/> : <FaChevronDown className='mx-2'/>}
                                    </div>
                                    {status && (
                                        <>
                                            {description.map((desc, index) => (
                                                <div key={index} className="footer__mobile--text mb-4 text-center">
                                                    {desc === "08079558667" ? <NavLink to="tel:+2348079558667" className="mx-3 footer--text text-decoration-none" target="_top">
                                                        07071488217
                                        </NavLink> : desc}
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>
                            </>
                        ))}
                    </div>
                </div>
                <div className="row text-center py-5 border-top border-bottom d-block d-md-none  footer__mobile">
                    {icons.map((icon, i) => (
                        <a href={icon.link} key={i} target="_blank" className="ps-3 text-reset text-light" rel="noopener noreferrer">
                            <img src={icon.icon} alt="icon" className="img-fluid" />
                        </a>
                    ))}
                </div>
                <div className="row text-center py-5 d-block d-md-none  ">
                    <p className='footer__mobile--name '>{currentYear} Klakpad,Inc.</p>
                    <NavLink to="/terms-and-conditions" className="text-decoration-none">
                        <p className="footer__mobile--name text-light pe-lg-5">Terms of Service</p>
                    </NavLink>
                    <NavLink to="/privacy-policy" className="text-decoration-none">
                        <p className="footer__mobile--name text-decoration-none text-light pe-lg-5">Privacy policy.</p>
                    </NavLink>
                </div>
            </div>
        </>
    )
}

export default FooterMobile
