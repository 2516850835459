import axios from 'axios'

export const getRequest = async (url: any) => {
    const result = await axios
        .get(url, {
            headers: {
                //   Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'multipart/json'
            }
        })
        .then((response) => response)
        .catch((error) => {
            throw error.response?.data || error.message
        })
    return result
}

export const PostRequest = async (url: any, data: any) => {
    const result = await axios
        .post(url, data, {
            headers: {
                Authorization: "Bearer "+localStorage.getItem('token'),
                Accept: 'application/json'
            }
        })
        .then((response) => response)
        .catch((error) => error.response)
    return result
}