import React from 'react'
import { NavLink } from 'react-router-dom'

const TermAndCondition = () => {
    const headings = {
        marginTop: '2rem',
        fontWeight: '500',
        fontSize: '25px'
    }
    const paragraphStyle: React.CSSProperties = { textAlign: 'justify' }

    return (
        <div className="container pt-3">
            <NavLink className=" " to="/">
                <img className="img-fluid " height={37} src="/assets/klakpad.svg" alt="logo" />
            </NavLink>
            <h4 style={{ marginTop: '6rem', marginBottom: '3rem', fontWeight: '600', fontSize: '25px' }}>Klakpad Terms & Conditions</h4>

            <h6 style={{ marginBottom: '3rem' }}>Last updated February 13, 2024.</h6>
            <p style={paragraphStyle}>
                These Terms and Conditions govern You (as defined below) use of the Services of the Platform (as defined below) provided by Klakpad Global
                Limited (“Klakpad” or “Us” or “We”) to You as a User.
            </p>

            <p style={paragraphStyle}>
                Please read these Terms and Conditions carefully as they affect Your rights and liabilities under the Applicable Laws. By accessing the
                Platform, You are indicating Your acceptance of these Terms and Conditions, the privacy policy and any other document applicable to your use of
                the Services (as defined below). If You do not agree to these Terms and Conditions, please do not access and or use the Platform. If You have
                any questions on the Terms and Conditions, please contact us at <a href="mailto:contact@klakpad.com">contact@klakpad.com</a>.
            </p>
            <h6 style={headings}>1. Definitions</h6>

            <ol>
                <li style={paragraphStyle}>
                    <strong>Account</strong> means the account to be opened and operated by You on the Platform with the features that enable You to access the
                    Services;
                </li>
                <li style={paragraphStyle}>
                    <strong>Affiliate</strong> means, in relation to a Party, a subsidiary or a holding company of that Party, or any subsidiary of the holding
                    company and all of its subsidiaries;
                </li>
                <li style={paragraphStyle}>
                    <strong>Applicable Laws</strong> means all laws in force and effect as of the date hereof and which may be enacted or brought into force and
                    effect hereinafter in the applicable Territory, including statutes, rules, regulations, directions, bye-laws, notifications, ordinances and
                    judgments having force of law, or any final interpretation by a court of law having jurisdiction over the matter in question as may be in
                    force and effect during the subsistence of these Terms;
                </li>
                <li style={paragraphStyle}>
                    <strong>AML/CFT Laws</strong> means any Applicable Laws concerning anti-money laundering or combating the financing of terrorism which
                    impose KYC and CDD obligations or other procedures for the mitigation of money laundering and financing of terrorism;
                </li>
                <li style={paragraphStyle}>
                    <strong>CDD</strong> means Customer Due Diligence;
                </li>
                <li style={paragraphStyle}>
                    <strong>Force Majeure</strong> means unforeseeable circumstances where the performance of Klakpad’s obligations is not reasonable or
                    practical. Such circumstances include natural calamities, accidents, fires, public disorders, walkouts, revolutions, hostilities,
                    legislative acts, government orders and directives of the authorities, inaccuracy, unreliability, or unsuitability of the contents of the
                    Platform which directly or indirectly prohibit the types of business stipulated herein, as well as any other circumstances beyond Klakpad’s
                    reasonable control.
                </li>
                <li style={paragraphStyle}>
                    <strong>Intellectual Property Rights</strong> means patents, rights to inventions, copyright and related rights, rights in software,
                    trademarks, business names and domain names, rights in get-up, goodwill and the right to sue for passing off, rights in designs, database
                    rights, rights to use, and protect the confidentiality of, Confidential Information (including know-how), and all other intellectual
                    property rights, in each case whether registered or unregistered and including all applications and rights to apply for and be granted,
                    renewals or extensions of, and rights to claim priority from, such rights and all similar or equivalent rights or forms of protection which
                    subsist or will subsist now or in the future in any part of the world relating to the businesses of the Parties or acquired by the Parties
                    from time to time;
                </li>
                <li style={paragraphStyle}>
                    <strong>KYC</strong> means Know-Your-Customer;
                </li>
                <li style={paragraphStyle}>
                    <strong>Platform</strong> means the computer software including this website, mobile application or any other digital platform that are
                    proprietary or licensed to Klakpad or its Affiliates from time to time and utilised by Klakpad to provide the Services;
                </li>
                <li style={paragraphStyle}>
                    <strong>Resolution Centre</strong> means the online resolution platform which can be accessed via your Account or by any other means as
                    Klakpad may from time to time make available;
                </li>
                <li style={paragraphStyle}>
                    <strong>Services</strong> means access to the Platform provided to You by Klakpad which enables You to make payments, send invoices, request
                    payments in accordance with these Terms;
                </li>
                <li style={paragraphStyle}>
                    <strong>Terms</strong> means the terms and conditions set out in this document titled as such, as the same may be amended from time to time
                    by Klakpad, at its sole discretion;
                </li>
                <li style={paragraphStyle}>
                    <strong>Territory</strong> means the countries where the Services are available;
                </li>
                <li style={paragraphStyle}>
                    <strong>Third Party Providers</strong> means the third party(ies) providing or hosting contents, products and/or services relating to the
                    Services including but not limited to partner financial institutions, cloud service providers, technical services providers etc;
                </li>
                <li style={paragraphStyle}>
                    <strong>Transaction</strong> means any payment or invoicing transaction between User in relation to the Services, regardless of whether the
                    transaction is approved or declined;
                </li>
                <li style={paragraphStyle}>
                    <strong>User</strong> means any individual who accesses and/or uses the Platform;
                </li>
                <li style={paragraphStyle}>
                    <strong>User Information</strong> means the information and documentation that You are required to provide while registering for an Account
                    on the Platform and as further set out in clause 4.8 herein;
                </li>
                <li style={paragraphStyle}>
                    <strong>You/Your</strong> refers to you as a User.
                </li>
            </ol>
            <h6 style={headings}>2. Introduction</h6>

            <p style={paragraphStyle}>The Platform is an operation and inventory platform that enables Users maximise its operation.</p>

            <p style={paragraphStyle}>
                We provide the Services on an independent service provider basis. You acknowledge that Transactions made by You constitute a business
                transaction solely between you and the recipient and that Klakpad is not a party to any Transaction that is processed using our Services.
            </p>

            <p style={paragraphStyle}>
                We do not endorse, have control or assume the liability or legality for the products or services that are paid for with our Services.
                Consequently, we do not guarantee the identity of the recipient of a Transaction and cannot ensure that the recipient of a Transaction will
                complete a transaction with the User.
            </p>

            <h6 style={headings}>3. Amendments</h6>

            <p style={paragraphStyle}>
                We may update these Terms from time to time for legal or regulatory reasons or for the proper operation of the Platform. Any changes will be
                notified to You via the e-mail address provided by You on registration or via a suitable announcement on the Platform. The changes will apply to
                the use of the Platform after we have given notice. If You do not wish to accept the new Terms You should not continue to use the Platform. If
                You continue to use the Platform after the date on which the change comes into effect, Your use of the Platform indicates Your agreement to be
                bound by the new Terms.
            </p>
            <h6 style={headings}>4. Registration - (Eligibility)</h6>

            <p style={paragraphStyle}>
                <strong>4.1.</strong> You may not register for an Account on this Platform if you are under 18 years of age. Any access to or use of the
                Platform or our Services by anyone under 18 is unauthorized, unlicensed, and in violation of these Terms of Use. By accessing or using the
                Services, you represent and warrant that you are 18 or older.
            </p>

            <p style={paragraphStyle}>
                <strong>4.2.</strong> In addition to 4.1 above, registration and/or sign-up as a User on this Platform shall only be conducted by companies,
                businesses or individuals who can form legally binding contracts under Applicable Laws.
            </p>

            <p style={paragraphStyle}>
                <strong>4.3.</strong> If You are registering on behalf of a company or a business or any other legal entity, you represent and warrant that you
                have the authority and consent of the legal entity.
            </p>

            <p style={paragraphStyle}>
                <strong>4.4.</strong> You must be domiciled in the Territory.
            </p>

            <p style={paragraphStyle}>
                <strong>4.5.</strong> To enable Us to provide the Services to You on the Platform, You are required to provide your User Information. You are
                required to provide accurate and complete information.
            </p>

            <p style={paragraphStyle}>
                <strong>4.6.</strong> It is a condition of Your use that all the User Information You provide on this Platform will be genuine, correct, current
                and complete. If Klakpad believes that the User Information You provide is not correct, or incomplete, Klakpad has the right to refuse You
                access to this Platform or any of its resources, and to terminate or suspend Your access at any time.
            </p>

            <p style={paragraphStyle}>
                <strong>4.7.</strong> Notwithstanding your provision of the User Information required by the Klakpad, access to the Platform or the Services
                remains at the discretion of the Klakpad.
            </p>

            <h6 style={headings}>Access</h6>

            <p style={paragraphStyle}>
                <strong>4.8.</strong> To access the Services, you must register to create an Account.
            </p>

            <p style={paragraphStyle}>
                <strong>4.9.</strong> When You register for the Account and from time to time thereafter, We may require You to provide and/or confirm
                information and documentation that will allow us identify You such as;
            </p>

            <ol type="a">
                <li style={paragraphStyle}>Your name</li>
                <li style={paragraphStyle}>Email address</li>
                <li style={paragraphStyle}>Mobile device number</li>
                <li style={paragraphStyle}>
                    A copy of Your government-issued photo ID, such as national ID, international passport, permanent voter’s card or driver’s license;
                </li>
                <li style={paragraphStyle}>Bank Verification Number (where applicable);</li>
                <li style={paragraphStyle}>
                    Such other information and documentation that we may require from time to time in accordance with AML/CFT Laws and our internal risk
                    assessment policies.
                </li>
            </ol>

            <p style={paragraphStyle}>
                <strong>4.10.</strong> When You register to use the Platform, you will be asked to create a password; to prevent fraud, you must keep this
                password confidential and must not disclose it or share it with anyone. If You know or suspect that someone else knows Your password, You should
                notify Us immediately.
            </p>

            <p style={paragraphStyle}>
                <strong>4.11.</strong> Your Account shall be used exclusively by You and You shall not transfer Your Account to any third party. You agree that
                you shall be solely responsible for any activities or actions under the Account, whether you have authorised such activities or actions. If You
                authorize any third party to manage Your Account on Your behalf, this shall be at Your own risk and Klakpad shall not be liable to You in any
                way for any loss or liability arising from use by the third party.
            </p>

            <p style={paragraphStyle}>
                <strong>4.12.</strong> You must follow reasonable instructions which we give You or publish on our Platform from time to time which are intended
                to help You to safely use the Services.
            </p>

            <p style={paragraphStyle}>
                <strong>4.13.</strong> If Klakpad has a reason to believe that there is likely to be a breach of security or misuse of the Platform, We may
                require You to change Your password or We may suspend Your Account.
            </p>

            <p style={paragraphStyle}>
                <strong>4.14.</strong> You agree to promptly notify Us if You change Your User Information by updating the Account; provided, however, that You
                must notify us at least three (3) working days before any changes to your Bank Account information, including but not limited to, the closure of
                the Bank Account information for any reason by emailing us or by updating your Account.
            </p>

            <p style={paragraphStyle}>
                <strong>4.15.</strong> If we approve your registration, you will be authorised to use the Services, subject to these Terms and the payment of
                the subscription fee as may be agreed with Klakpad.
            </p>

            <h6 style={headings}>5. Transaction and Service Procedure</h6>

            <p style={paragraphStyle}>
                <strong>5.1.</strong> You may initiate a Transaction or Service by providing the Information or required details on the Platform.
            </p>

            <p style={paragraphStyle}>
                <strong>5.2.</strong> You shall ensure that the Information or details are correct and complete. Neither Klakpad, Affiliates or Third Party
                Providers (and their employees, agents, officers, directors and contractors) shall be held liable for any loss or damage arising directly or
                indirectly from an error in the Information.
            </p>

            <p style={paragraphStyle}>
                <strong>5.3.</strong> You cannot cancel or reverse a Transaction or Service upon Your confirmation of the Transaction or Service.
            </p>

            <p style={paragraphStyle}>
                <strong>5.4.</strong> We may refuse to complete or reverse any Transaction or Service if:
            </p>

            <ol type="a">
                <li style={paragraphStyle}>the Transaction or Service is one that Klakpad cannot process;</li>
                <li style={paragraphStyle}>
                    the Transaction or Service violates Applicable Laws, these Terms or any other agreement the User may have with Klakpad;
                </li>
                <li style={paragraphStyle}>You are in violation of Applicable Laws, these Terms or any other agreement the User may have with Klakpad;</li>
                <li style={paragraphStyle}>
                    Klakpad is unable to commence or complete the Transaction or Service due to a Third-Party Provider’s failure, equipment operational failure
                    or malfunction or other causes beyond Klakpad’s control or reasonable control;
                </li>
                <li style={paragraphStyle}>
                    Klakpad becomes aware that the Transaction or Service originates from or involves a counterfeit or fraudulently prepared, issued or altered
                    payment instrument or the proceeds of fraud, a breach of trust, or criminal activity; or
                </li>
                <li style={paragraphStyle}>
                    for any other reason, Klakpad in its discretion, reasonably considers necessary to protect its interests, whether legal, reputational or
                    otherwise.
                </li>
            </ol>

            <p style={paragraphStyle}>
                <strong>5.5.</strong> Upon successful completion of a Transaction, You can download a proof of payment receipt or copy of the invoice, on our
                Platform.
            </p>

            <p style={paragraphStyle}>
                <strong>5.6.</strong> You must follow our online dispute resolution process through the Resolution Centre to resolve a failed or disputed
                Transaction.
            </p>

            <p style={paragraphStyle}>
                <strong>5.7.</strong> We will notify you of the cost of providing you with any of our Service and continuing with the Transaction or Service
                will constitute agreement to the fees.
            </p>
            <h6 style={headings}>6. Use of the Platform</h6>

            <p style={paragraphStyle}>
                <strong>6.1.</strong> You must not use the Platform in connection with illegal activity including but not limited to money-laundering, fraud and
                the funding of terrorist organizations. If we reasonably believe You are using the Platform in connection with illegal activity or for any
                fraudulent purpose, or are permitting a third party to do so, we shall report You to the appropriate legal authorities.
            </p>

            <p style={paragraphStyle}>
                <strong>6.2.</strong> When using the Account or the Services, You must do the following:
            </p>

            <ol type="a">
                <li style={paragraphStyle}>
                    <strong>6.2.1.</strong> comply with these Terms as well as any Applicable Laws, rules or regulations;
                </li>
                <li style={paragraphStyle}>
                    <strong>6.2.2.</strong> provide confirmation of any information You provide to us, including proof of identity;
                </li>
                <li style={paragraphStyle}>
                    <strong>6.2.3.</strong> co-operate in any investigation that we reasonably carry out, or that is carried out by any law enforcement agency,
                    government agency or regulatory authority;
                </li>
                <li style={paragraphStyle}>
                    <strong>6.2.4.</strong> not provide false, inaccurate, or misleading information; and
                </li>
                <li style={paragraphStyle}>
                    <strong>6.2.5.</strong> not use an anonymising proxy.
                </li>
            </ol>

            <p style={paragraphStyle}>
                <strong>6.3.</strong> Provided it would not be unlawful for us to do so, and it would not compromise reasonable security measures, we will
                contact You by phone or email if there is an actual or suspected fraud affecting Your use of the Platform, or a security threat affecting the
                Platform or Your Account.
            </p>

            <p style={paragraphStyle}>
                <strong>6.4.</strong> You hereby authorise Us to share, receive, and use data/information collected from your use of the Services with our
                Affiliates or Third-Party Providers in order to provide the Services to you or improve our Services. You consent to our transferring Your data
                to recipients in foreign countries to process such data in order to provide the Services to you.
            </p>

            <p style={paragraphStyle}>
                <strong>6.5.</strong> We can send all important communications, billing statements and demand notes and reminders to You electronically via our
                Platform or to an email address that You provide to Us while opening the Account.
            </p>

            <p style={paragraphStyle}>
                <strong>6.6.</strong> Nothing in these Terms or in any information provided by Klakpad as part of the Services covered by these Terms is
                intended to be, nor should it be construed to be, legal or other advice. You must consult Your own professional advisers as to the effect of
                laws which may apply to Your use of the Services.
            </p>

            <h6 style={headings}>7. Privacy Statement</h6>

            <p style={paragraphStyle}>
                Your use of this Platform signifies Your continuing consent to Klakpad’s Privacy Policy which You can examine at any time by clicking of the
                “privacy” link on the Platform.
            </p>

            <p style={paragraphStyle}>
                Personal information that You supply to Klakpad and any information about Your use of the Platform that we obtain will be subject to the
                Klakpad’s Privacy Policy.
            </p>
            <h6 style={headings}>8. Availability of the Platform</h6>

            <p style={paragraphStyle}>
                <strong>8.1.</strong> Although we aim to offer You the best service possible, we make no promise that the Platform will meet Your requirements.
                We cannot guarantee that the Services will be fault-free. If a fault occurs with the Platform You should report to us and we will attempt to
                correct the fault as soon as We reasonably can.
            </p>

            <p style={paragraphStyle}>
                <strong>8.2.</strong> Your access to the Platform may be occasionally restricted to allow for repairs, maintenance or the introduction of new
                facilities or services. We will attempt to restore the service as soon as we reasonably can.
            </p>

            <h6 style={headings}>9. Proprietary Information</h6>

            <p style={paragraphStyle}>
                The material and content accessible from this Platform, and any other electronic platform owned, operated, licensed or controlled by Klakpad is
                the proprietary information of Klakpad. Klakpad retains all rights, title, and interest in the content. Accordingly, the content may not be
                copied, distributed, republished, uploaded, posted or transmitted in any way without the prior written consent of Klakpad, except that You may
                print out a copy of the Content solely for your personal use. In doing so, You may not remove or alter, or cause to be removed or altered, any
                copyright, trademark, trade name, service mark, or any other proprietary notice or legend appearing on any of the Content. Modification or use
                of the content except as expressly provided in these Terms violates Klakpad’s Intellectual Property Rights. Neither title nor Intellectual
                Property Rights are transferred to You by access to this Platform.
            </p>

            <h6 style={headings}>10. Trademark</h6>

            <p style={paragraphStyle}>
                Trademarks, service marks, and logo appearing in this Platform are the property of Klakpad or the party that provided the trademarks, service
                marks and logos to Klakpad. Klakpad and any party that provided trademarks, service marks and logos to Klakpad retain all rights with respect to
                any of their respective trademarks, service marks, and logos appearing in this Platform.
            </p>
            <h6 style={headings}>11. Limitation of Liabilities and Disclaimer</h6>

            <p style={paragraphStyle}>
                <strong>11.1.</strong> THE PLATFORM AND ALL INFORMATION, CONTENT, MATERIALS, PRODUCTS AND OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE
                TO YOU THROUGH THE APP ARE PROVIDED BY THE COMPANY ON AN "AS IS" AND "AS AVAILABLE" BASIS, UNLESS OTHERWISE SPECIFIED IN WRITING. THE COMPANY
                MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE APP, OR THE INFORMATION, CONTENT, MATERIALS,
                PRODUCTS OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE APP, UNLESS OTHERWISE SPECIFIED IN WRITING. YOU EXPRESSLY
                AGREE THAT YOUR USE OF THE APP IS AT YOUR SOLE RISK.
            </p>

            <p style={paragraphStyle}>
                <strong>11.2.</strong> TO THE FULL EXTENT PERMISSIBLE BY LAW, THE COMPANY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT
                LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. KLAKPAD DOES NOT WARRANT THAT THE PLATFORM, INFORMATION,
                CONTENT, MATERIALS, PRODUCTS OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE PLATFORM, OR ELECTRONIC COMMUNICATIONS
                SENT BY KLAKPAD ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. TO THE FULL EXTENT PERMISSIBLE BY LAW, KLAKPAD WILL NOT BE LIABLE FOR ANY
                DAMAGES OF ANY KIND ARISING FROM THE USE OF THE PLATFORM, OR FROM ANY INFORMATION, CONTENT, MATERIALS, PRODUCTS OR OTHER SERVICES INCLUDED ON OR
                OTHERWISE MADE AVAILABLE TO YOU THROUGH THE KLAKPAD, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL
                DAMAGES, UNLESS OTHERWISE SPECIFIED IN WRITING.
            </p>

            <p style={paragraphStyle}>
                <strong>11.3.</strong> You understand that Klakpad cannot and does not guarantee or warrant that files available for downloading from the
                internet will be free of viruses, worms, Trojan horses, or other code that may manifest contaminating or destructive properties. You are
                responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for accuracy of data input and
                output, and for maintaining a means external to this Platform for the reconstruction of any lost data. Klakpad does not assume any
                responsibility or risk for your use of the Internet.
            </p>

            <p style={paragraphStyle}>
                <strong>11.4.</strong> KLAKPAD, IT’S SUBSIDIARIES, AFFILIATES, LICENSORS, SERVICE PROVIDERS, CONTENT PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, AND
                DIRECTORS WILL NOT BE LIABLE FOR ANY INCIDENTAL, DIRECT, INDIRECT, PUNITIVE, ACTUAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR OTHER DAMAGES, EVEN
                IF THE COMPANY HAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </p>
            <h6 style={headings}>12. Hyper-Links</h6>

            <p style={paragraphStyle}>
                This Platform may be hyper-linked to other websites which are not maintained by or related to, Klakpad. Klakpad has not reviewed any of such
                platforms and is not responsible for the content of those websites. Hyper-Links are to be accessed at the User’s own risk, and Klakpad makes no
                representations or warranties about the content, completeness, or accuracy of these hyper-links or the websites hyper-linked to this Platform.
                Further, the inclusion of any hyper-link to a third-party website does not necessarily imply endorsement by Klakpad of that website or
                third-party.
            </p>

            <h6 style={headings}>13. Security</h6>

            <p style={paragraphStyle}>
                You are prohibited from using any services or facilitates provided in connection with this Platform to compromise security or tamper with system
                resources and or Accounts. The use or distribution of tools designed for compromising security (e.g., password guessing programs, cracking
                tools, or network probing tools) is strictly prohibited. If you become involved in any violation of system security, Klakpad reserves the right
                to investigate suspected violations of Terms of Use.
            </p>
            <p style={paragraphStyle}>
                Klakpad reserves the right to fully cooperate with any law enforcement authorities or court order requesting or directing Klakpad to disclose
                the identity of anyone posting any e-mail messages or publishing or otherwise making available any materials that are believed to violate these
                Terms. BY ACCEPTING THESE TERMS AND CONDITIONS YOU WAIVE AND HOLD HARMLESS KLAKPAD FROM ANY CLAIMS RESULTING FROM OR ANY ACTION TAKEN BY KLAKPAD
                DURING OR AS A RESULT OF ITS ASSISTANCE TO INVESTIGATIONS BY EITHER OR LAW ENFORCEMENT AUTHORITIES.
            </p>
            <h6 style={headings}>14. Indemnity</h6>

            <p style={paragraphStyle}>
                You will indemnify and hold Klakpad, its Affiliates, Third Party Providers (and their employees, agents, officers, directors, and contractors)
                (the “Indemnified Parties”) harmless from any breach of these Terms by You, including any use of content other than as expressly authorized in
                these Terms. You agree that the Indemnified Parties will have no liability in connection with any such breach or unauthorized use, and you agree
                to indemnify the Indemnified Parties against any and all resulting loss, damages, judgments, awards, costs, expenses and attorney fees in
                connection therewith. You will also indemnify and hold the Indemnified Parties from and against any claims brought by third parties arising out
                of your use of the information accessed from this Platform.
            </p>
            <h6 style={headings}>15. Advertising and Sponsorship</h6>

            <p style={paragraphStyle}>
                Part of the Platform may contain advertising and sponsorship. Advertisers and sponsors are responsible for ensuring that material submitted for
                inclusion on the Platform complies with relevant laws and codes. We will not be responsible to you for any error or inaccuracy in advertising
                and sponsorship material.
            </p>

            <h6 style={headings}>16. Termination and Suspension of Accounts</h6>

            <p style={paragraphStyle}>
                <strong>16.1.</strong> You may terminate your Account by closing your Account on the Platform. In certain cases, you may not close your Account,
                including:
                <br />
                (a) Possible evasion of an investigation.
                <br />
                (b) Existence of a pending Transaction or an open dispute or claim.
            </p>

            <p style={paragraphStyle}>
                <strong>16.2.</strong> We may suspend or terminate your Account or cease providing You with all or part of the services at any time, including
                but not limited to, if We reasonably believe; (i) You do not comply with any of the provisions of these Terms, (ii) you create risk or possible
                legal exposure to Us, (iii) we are required by Applicable Law to do so, (iv) You have engaged in fraudulent or suspicious transactions.
            </p>

            <p style={paragraphStyle}>
                <strong>16.3.</strong> The termination of Your Account shall not affect or prejudice any rights or obligations which have accrued or arisen
                under these Terms prior to the time of termination and such rights and obligations shall survive the termination of these Terms.
            </p>

            <p style={paragraphStyle}>
                <strong>16.4.</strong> If you're unable to resolve a transaction related issue directly with a seller, you must follow our online dispute
                resolution process through the Resolution Centre to pursue a claim under our Buyer Protection program.
            </p>
            <h6 style={headings}>Applicable Law and Jurisdiction</h6>

            <p style={paragraphStyle}>
                <strong>17.1.</strong> These Terms will be subject to the laws of the Federal Republic of Nigeria. We will try to solve any disagreements
                quickly and efficiently. If you are not happy with the way we deal with any disagreement and you want to take court proceeding you must do so
                within Nigeria.
            </p>

            <p style={paragraphStyle}>
                <strong>17.2.</strong> You agree that any dispute or claims that you may have against us will be will in the first instance be attempted to be
                settled amicably by the Parties.
            </p>

            <p style={paragraphStyle}>
                <strong>17.3.</strong> If the dispute, difference, or claim is not settled amicably within 14 days, the dispute, difference or claim shall be
                referred to a Mediator. You shall bear the own costs and would be liable to contribute the same amount in respect of fees to be paid to the
                mediator. If mediation is not concluded within 3 months of the commencement of the mediation, you reserve your right to seek redress in the High
                Court of Lagos State.
            </p>

            <h6 style={headings}>18. Miscellaneous</h6>

            <p style={paragraphStyle}>
                <strong>18.1.</strong> You may not transfer any of your rights under these Terms to any other person. We may transfer our rights under these
                Terms to a third-party where we reasonably believe your rights will not be affected.
            </p>

            <p style={paragraphStyle}>
                <strong>18.2.</strong> The failure of Klakpad to enforce any right or provision of these Terms shall not constitute a waiver of such right or
                provision or the right to later enforce that or any other part of these Terms.
            </p>

            <p style={paragraphStyle}>
                <strong>18.3.</strong> Klakpad shall not be responsible for any breach of these Terms caused by Force Majeure.
            </p>

            <p style={paragraphStyle}>
                <strong>18.4.</strong> Should any provision of these Terms be held to be invalid, unlawful or unenforceable, such provision will be severable
                from the remaining provisions which will continue to be valid and enforceable.
            </p>

            <p style={paragraphStyle}>
                By clicking I Agree/Accept, you confirm that you have read this Terms, that you understand them and agree to be bound by the terms contained
                herein.
            </p>
        </div>
    )
}

export default TermAndCondition
