import React from 'react'
import SectionTab from './SectionTab'
import '../styles/BenefitSection1.css'
interface benefitSectionProps {
    head: string
    heading: string
    description: string
    icon: any
    img: any
}
const BenefitSection1: React.FC<benefitSectionProps> = ({ head, heading, description, img, icon }) => {
    return (
        <div
            style={{ color: '#111A24' }}
            className="aos-init aos-animate animate__animated animate__backInLeft  animate__delay-.5s p-md-4"
            data-aos="fade-up"
            data-aos-duration="500"
        >
            <div className="container p-md-5 pb-md-0">
                <div className="">
                    <h3 className="mb-2 ps-md-none ps-3" id="benefit-head">
                        {head}
                    </h3>
                </div>
                <div className="row align-items-center p-0 mb-md-3">
                    <div className="col-12  col-md-5 mb-4 benefit--img ">
                        <img src={img} alt="" className="img-fluid p-3 p-md-none" />
                    </div>
                    <div className="col-12 col-md-7">
                        <SectionTab heading={heading} description={description} icon={icon} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BenefitSection1
