import { PlanDetailsType, UPDATE_PLANDETAILS_STATES } from "../actions/planDetails";

type PlanDetailsProps = {
    type: string;
    payload: PlanDetailsType;
};

const INITIAL_STATE = {
    subAmount: 0,
    couponDiscount: 0,
    finalTotal: 0,
    topupAmount: 0,
    saved_card: 0,
    ordersSummary:[],
    payment_method: "",
    plan_id: "",
    coupon: "",
    duration: "",
    location_id: "",
    card_type:"new_card",
    additional_products:0,
    additional_users: 0,
    additional_warehouse: 0,
    email: "",
    auth_code:"",
    validation: false,
    rerender: false,
    finalTotals: 0, 
    additionalUsers: 0, 
    additionalOutlets: 0, 
    additionalProducts: 0,
    name: "",
    loader: false,
    isOnboarding: "",
    self: 0,
    agent: 0,
    onboarding: '',
    onboardingData: []
};

const planDetails = (planDetailsStates = INITIAL_STATE, action: PlanDetailsProps) => {
    switch (action.type) {
        case UPDATE_PLANDETAILS_STATES:
            return { ...planDetailsStates, ...action.payload };
        default:
            return planDetailsStates;
    }
};
  
export default planDetails;