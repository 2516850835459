import React, { useEffect, useState } from 'react'
import { CiLaptop } from 'react-icons/ci'
import { IoWalletOutline } from 'react-icons/io5'
import { GoArrowRight, GoPlus } from 'react-icons/go'
import { BiInfoCircle } from 'react-icons/bi'
import { FaArrowRight } from 'react-icons/fa6'
import AddMoney from './addMoney'
import PayWithCard from './payWithCard'
import { planDetailsStates } from './planDetailsStates'
import { PostRequest } from '../../resources/api-request'
import { updatePlanDetailsStates } from '../../store/actions/planDetails'
import { useDispatch } from 'react-redux'
import { format } from '../../utility/functions'
import { BsArrowRight } from "react-icons/bs";
import { SmallLoader } from '../../components/loader'
import { calculateCharges } from './calculatePaystackCharges'
import { useNavigate } from 'react-router-dom'

const PayWithWallet = ({ formattedAmount, plan_duration, planDetails,  handleRadioChange, paymentMethodChoices}: any) => {
    const walletBalance = +planDetails?.walletBalance ?? 0
    const amountNeeded = plan_duration === 'yearly' ? planDetails?.amountYear : planDetails?.amountMonth
    const isBalanceTooLow = walletBalance < amountNeeded;

    const [loader, setLoader]=useState(false);
    const navigate = useNavigate()
    const dispatch = useDispatch();
  
    const {
        ordersSummary, 
        finalTotal=0, 
        couponDiscount=0, 
        plan_id, 
        duration,
        payment_method,
        coupon,
        rerender
    } = planDetailsStates();

    let finalTotals = finalTotal;
    let additionalUsers = 0;
    let additionalOutlets = 0;
    let additionalProducts = 0;

    if(ordersSummary && ordersSummary.length > 0){
        const orderAmount = ordersSummary.reduce((acc: number, item: any) => {
            return acc + item.price;
        }, 0);

        finalTotals = (orderAmount + finalTotal);
   
        ordersSummary.map((item: any) => {
            if(item.name=='Additional Active Users') {
                additionalUsers = item.qty
            } else if(item.name == 'Additional Unique Outlets') {
                additionalOutlets = item.qty
            } else if(item.name == 'Additional Unique Products') {
                additionalProducts = item.qty
            }
        });
    }
    

    const subscribeWithWallet = async () => {
       
        setLoader(true);
        const response = await PostRequest("/subscribe-with-wallet",{
            plan_amount: amountNeeded,
            total_amount: (finalTotals + calculateCharges(finalTotals)) + couponDiscount,
            discount_percentage: couponDiscount,
            amount_paid: finalTotals + calculateCharges(finalTotals),
            payment_method,
            plan_id,
            coupon,
            duration,
            location_id: "",
            additional_products:additionalProducts,
            additional_users: additionalUsers,
            additional_warehouse: additionalOutlets
        });
        
        if(response.data.error==false){
            finalTotals = finalTotal;
            additionalUsers = 0;
            additionalOutlets = 0;
            additionalProducts = 0;

            dispatch(updatePlanDetailsStates({
                ordersSummary: [], 
                couponDiscount:0, 
                plan_id: '', 
                duration:'',
                payment_method:'',
                coupon:''
            }));
        }

        setLoader(false);
        alert(response.data.message);
        navigate('/download')
    }
    
    return (
        <>
            <div className="modal border-0 fade" id="exampleModal" tabIndex={-2} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content p-3">
                        <div className="modal-header border-0">
                            <button type="button" className="btn-close " data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="text-center mb-2 pb-1">
                                {loader && <SmallLoader />}
                                {' '}
                                <CiLaptop fontSize={20} className="mx-1" /><span className="mt-1">{plan_id == '2' ? 'Plus' : 'Enterprise'} plan</span>
                            </div>
                            <h1 className=" summary__card--amount text-center fs-2" id="exampleModalLabel">
                                ₦{format(finalTotals + calculateCharges(finalTotals))}
                            </h1>

                            <div className="card border-0 mt-5">
                                <div className="card-body">
                                    <div className="d-flex mb-4">
                                        <div className="flex-grow-1" style={{color:'#60666D', fontSize: '14px', fontWeight:'400'}}>Payment for</div>
                                        <div className="text-end">
                                            <span className='summary__card--text3'>
                                                {plan_id == '2' ? 'Plus' : 'Enterprise'} plan ({plan_duration})
                                            </span>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="flex-grow-1" style={{color:'#60666D', fontSize: '14px', fontWeight:'400'}}>Amount</div>
                                        <div className="text-end">
                                            <span className='summary__card--text3'>₦{format(finalTotals + calculateCharges(finalTotals))}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="card border-0"
                                style={{
                                    backgroundColor: '#F2F5F7'
                                }}
                            >
                                <div className="card-body">
                                    <div className="d-flex align-items-center ">
                                        <div className="flex-grow-1">
                                            <span style={{backgroundColor: '#E6EBF5', padding: '5px', borderRadius: '50px'}} className='mx-2'> <IoWalletOutline color="#2C4A8B" fontSize={18} /></span>
                                            <span className="mt-1" style={{color: '#888C91'}}>
                                            Wallet Balance <b className='text-dark'>(₦{new Intl.NumberFormat().format(planDetails?.walletBalance)})</b>
                                            </span>
                                        </div>
                                        <div className="text-end ">
                                            <button
                                                className="  p-1 rounded  plans__card--btn d-flex align-items-center"
                                                data-bs-toggle="modal"
                                                data-bs-target="#payWithCardModal"
                                                onClick={() => dispatch(updatePlanDetailsStates({rerender: !rerender}))}
                                            >
                                                <GoPlus fontSize={16} className=''/>
                                               <span className='fw-medium ps-1'> Add Money</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {isBalanceTooLow ? (
                                <div className="text-danger">
                                    <BiInfoCircle />
                                    <span>Your wallet balance is too low for this transaction</span>
                                </div>
                            ) : null}
                            <div className="row mt-5 mb-3">
                                <div className="card border-0">
                                    <button onClick={subscribeWithWallet} className="w-100 py-2 summary__card--btn  ">
                                       <div className='d-flex align-items-center justify-content-center'> 
                                       <span className='pe-1'>Proceed to payment </span>
                                      
                                      <img src='/assets/icons/arrRight.svg' className='mx-2 img-fluid' width={18}/></div>
                                        
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PayWithCard planDetails={planDetails}  handleRadioChange={handleRadioChange} paymentMethodChoices={paymentMethodChoices} />
        </>
    )
}
export default PayWithWallet
