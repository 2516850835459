import React, { useState } from 'react'
import './styles/manageSection.css'
import { COLORS } from '../../../../resources/colors-contants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'

interface manageInterface {
    id?: string
    head: string
    body: string
    shortIt?: boolean
    colapse?: boolean
    display?: boolean
    toggleCollapse?: () => void
}

const headStyle = {
    color: COLORS.product_headings,
    fontWeight: '600'
}
const descStyle = { color: COLORS.product_paragraph, fontWeight: '400' }
const ManageSection: React.FC<manageInterface> = ({ id, head, body, shortIt, colapse, display, toggleCollapse }) => {
    const [showMore, setShowMore] = useState(false)

    const toggleShowMore = () => {
        setShowMore(!showMore)
    }

    return (
        <div className="my-3 my-md-5">
            <div>
                <h2 id="manageHeadStyle" className="d-flex justify-content-between" style={headStyle}>
                    <span onClick={toggleCollapse} className="d-block d-md-none">
                        {head}
                    </span>
                    <span className="d-none d-md-block">{head}</span>
                    {display ? (
                        colapse ? (
                            <span onClick={toggleCollapse} className="d-block d-md-none py-1" style={{ color: COLORS.primary_background }}>
                                <FontAwesomeIcon icon={faMinus} />
                            </span>
                        ) : (
                            <span onClick={toggleCollapse} className="d-block d-md-none py-1" style={{ color: COLORS.primary_background }}>
                                <FontAwesomeIcon icon={faPlus} />
                            </span>
                        )
                    ) : (
                        ''
                    )}
                </h2>
            </div>
            <div>
                {!display ? (
                    <p id="manageDescStyle" style={descStyle}>
                        {shortIt ? (
                            <>
                                {showMore ? body : body.slice(0, 160)}
                                {!showMore && body.length > 160 && (
                                    <span onClick={toggleShowMore} style={{ color: COLORS.primary_background, fontWeight: '500', cursor: 'pointer' }}>
                                        ... Show more
                                    </span>
                                )}
                                {showMore && (
                                    <span onClick={toggleShowMore} style={{ color: COLORS.primary_background, fontWeight: '500', cursor: 'pointer' }}>
                                        Show less
                                    </span>
                                )}
                            </>
                        ) : (
                            body
                        )}
                    </p>
                ) : (
                    colapse && (
                        <p id="manageDescStyle" style={descStyle}>
                            {body}
                        </p>
                    )
                )}
            </div>
        </div>
    )
}

export default ManageSection
