import React from 'react'
import Product from './component/product'

const index = () => {
    return (
        <div>
            <Product />
        </div>
    )
}

export default index
