export const ROUTES = {
    HOMEPAGE_ROUTE: '/',
    DOWNLOAD: '/download',
    PRODUCTS: '/products',
    PRICING: '/pricing',
    CONTACT: '/contact',
    LOGIN: '/login',
    TRANSACTIONS: '/transactions',
    DASHBOARD: '/dashboard',
    PLANS_AND_BILLINGS: '/plans-and-billings',
    SALES_REPORT: '/sales-report',
    USER_MANAGEMENT: '/user-management',
    SIGNUP: '/signup',
    VERIFY_EMAIL: '/verify-email',
    VERIFY_PHONE: '/verify-phone',
    FORGOT_PASSWORD: '/forgot-password',
    FORGOT_OPT_RESET: '/forgot-opt-reset',
    RESET_PASSWORD: '/reset-password',
    SET_NEW_PASSWORD: '/set-newpassword',
    DOWNLOAD_PLATFORM: '/download/:platform',
    PRIVACY_POLICY: '/privacy-policy',
    TERMS_AND_CONDITIONS: '/terms-and-conditions',
    LOCATIONS: '/locations',
    WALLET: '/wallet',
    NOT_FOUND: '*'
}
