import React, { useEffect, useRef, useState } from 'react'
import Tippy from '@tippyjs/react'
import { useQuery } from 'react-query'
import { getRequest } from '../../../resources/api-request'
import { Link } from 'react-router-dom'

const PlanCategories: React.FC = () => {
    const { isLoading, isError, data } = useQuery<any>('result', () => getRequest('subscription-plans'))
    const [active, setActive] = useState<number>(2)
    const ref = useRef<any>(null)
    const [isSticky, setSticky] = useState(false)

    console.log(data?.data, 'mobile')

    const handleScroll = () => {
        console.log('scrolling!!!')
        // alert(ref.current)
        // if (ref.current) {
        //     setSticky(ref.current.getBoundingClientRect().top <= 0)
        // }
    }
    useEffect(() => {
        // Attach the event listener
        window.addEventListener('scroll', handleScroll)

        // Detach the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
        <>
            <div className="container-fluid ps-4 pe-4 ">
                <div className="row d-block d-md-none  justify-content-center ">
                    <p className="  plans__mobile--title text-center mb-5 ">Compare Plan and Features</p>
                    <div className="plans__scroll">
                        <ul className="nav justify-content-center  plans__mobile ">
                            {data?.data?.headers?.map(({ name, id }: any, index: number) => (
                                <>
                                    <li className="nav-item" key={id}>
                                        <Link
                                            className={`nav-link ${active === index + 1 ? 'active' : ''}`}
                                            id={`tab${index + 1}-tab`}
                                            data-bs-toggle="tab"
                                            to={`#tab${index + 1}`}
                                            onClick={() => setActive(index + 1)}
                                        >
                                            {name}
                                        </Link>
                                    </li>
                                </>
                            ))}
                        </ul>

                        {data?.data?.headers?.map(({ name }: any, index: number) => (
                            <div className="scrolling-content" key={index}>
                                <div className="tab-content ">
                                    <div className="tab-pane fade show active" id={`tab${index + 1}`}>
                                        {name === 'Basic' ? (
                                            <>
                                                {data?.data?.head_features?.map(({ name, features }: any, index: number) => (
                                                    <div key={index}>
                                                        <h3 className="mb-5 mt-4 pt-5 plans__mobile--title"> {name}</h3>
                                                        {features?.map(({ id, features, basic, description, basic_value }: any) => (
                                                            <div className="row align-items-center  border-bottom py-4" key={id}>
                                                                <div className="col-7 text-start plans__mobile--features">
                                                                    {features}
                                                                    <Tippy
                                                                        content={
                                                                            <div className="card border-0 shadow p-2">
                                                                                <div className="card-body p-4">
                                                                                    <h5 className=" mb-3">{features}</h5>
                                                                                    <p className="">{description}</p>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        arrow={false}
                                                                        placement="bottom"
                                                                    >
                                                                        <i
                                                                            className="material-symbols-outlined ps-1 pt-1"
                                                                            style={{ fontSize: '15.33px', color: '#2C4A8B' }}
                                                                        >
                                                                            info
                                                                        </i>
                                                                    </Tippy>
                                                                </div>
                                                                <div className="col-5 text-end plans__mobile--text">
                                                                    {basic === 1 ? (
                                                                        <span>{basic_value === 0 ? <i className="fa-solid fa-check" /> : basic_value}</span>
                                                                    ) : (
                                                                        <i className="fa-solid fa-cancel" />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}
                                            </>
                                        ) : (
                                            ''
                                        )}
                                        {name === 'Plus' ? (
                                            <>
                                                {data?.data?.head_features?.map(({ name, features }: any) => (
                                                    <div key={index}>
                                                        <h3 className="mb-5 mt-4 pt-5 plans__mobile--title">{name}</h3>
                                                        {features?.map(({ id, features, plus, description, plus_value }: any) => (
                                                            <div className="row align-items-center py-4 border-bottom" key={id}>
                                                                <div className="col-8 text-start plans__mobile--features ">
                                                                    {features}
                                                                    <Tippy
                                                                        content={
                                                                            <div className="card border-0 shadow p-2">
                                                                                <div className="card-body p-4">
                                                                                    <h5 className=" mb-3">{features}</h5>
                                                                                    <p className="">{description}</p>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        arrow={false}
                                                                        placement="bottom"
                                                                    >
                                                                        <i
                                                                            className="material-symbols-outlined ps-1 pt-1"
                                                                            style={{ fontSize: '15.33px', color: '#2C4A8B' }}
                                                                        >
                                                                            info
                                                                        </i>
                                                                    </Tippy>
                                                                </div>
                                                                <div className="col-4 text-end plans__mobile--text">
                                                                   {plus === 1 ? (
                                                              <span>{plus_value === 0 ?  <i className="fa-solid fa-check" /> : plus_value}</span>
                                                              ) : <i className="fa-solid fa-cancel" /> }
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}
                                            </>
                                        ) : (
                                            ''
                                        )}
                                        {name === 'Enterprise' ? (
                                            <>
                                                {data?.data?.head_features?.map(({ name, features }: any) => (
                                                    <div key={index}>
                                                        <h3 className="mb-5 mt-4 pt-5 plans__mobile--title">{name}</h3>
                                                        {features.map(({ id, features, enterprise, description, enterprise_value }: any) => (
                                                            <div className="row align-items-center border-bottom py-4" key={id}>
                                                                <div className="col-8 text-start plans__mobile--features d-flex align-items-center">
                                                                    {features}
                                                                    <Tippy
                                                                        content={
                                                                            <div className="card border-0 shadow p-2">
                                                                                <div className="card-body p-4">
                                                                                    <h5 className=" mb-3">{features}</h5>
                                                                                    <p className="">{description}</p>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        arrow={false}
                                                                        placement="bottom"
                                                                    >
                                                                        <i
                                                                            className="material-symbols-outlined ps-1 pt-1"
                                                                            style={{ fontSize: '15.33px', color: '#2C4A8B' }}
                                                                        >
                                                                            info
                                                                        </i>
                                                                    </Tippy>
                                                                </div>
                                                                <div className="col-4 text-end plans__mobile--text">
                                                                    {enterprise === 1 ? (
                                                                        <span>
                                                                            {enterprise_value === 0 ? <i className="fa-solid fa-check" /> : enterprise_value}
                                                                        </span>
                                                                    ) : (
                                                                        <i className="fa-solid fa-cancel" />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}
export default PlanCategories
