import React from "react";

const ContactHero = () => {
    return(
        <>
            <div className="contact py-5">
                <div className="container py-5">
                    <div className="row text-center py-5 justify-content-center">
                        <h3 className="contact--title">Connect with our Sales team.</h3>
                        <p className="contact--text">We’ll need few details from you to get started.</p>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ContactHero;