import React, { ChangeEvent, useState } from 'react'
import { postRequest2 } from '../../utility/apiRequest';


const ChangeNumber = () => {
    const [phone, setPhone] = useState('');
    const [closeModal, setCloseModal] = useState(false)
    const [userMessage, setUserMessage] = useState("");
    const [userResponseType, setUserResponseType] = useState<boolean>(false)

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPhone(e.target.value);

    }
    const submitForm = async() => {
        const result = await postRequest2('edit-phone-number', { phone });
        
        if (result.data.success === true) {
            localStorage.setItem('token', result.data.api_token)
            setCloseModal(true)
            setUserMessage(result.data.message)
            setUserResponseType(true);
            setTimeout(() => {
                setUserMessage('')
            }, 5000)
        }
        if (result.data.success !== true) {
            setUserMessage(result.data.message)
            setTimeout(() => {
                setUserMessage('')
            }, 5000)
        }
        
    }
    return (
        <>
            <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div
                        className="modal-content"
                        style={{
                            padding: '20px 31px'
                        }}
                    >
                        <div className="modal-header border-0">
                            <button type="button" className="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <h5 className="ms-3" style={{
                            marginBottom: 56,
                            fontSize: '20px',
                            fontWeight: 600,
                            letterSpacing: -0.2
                        }}>Change phone number</h5>
                        <div className="modal-body">
                            
                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                    {userMessage && <p className={userResponseType ? 'alert alert-success' : 'alert alert-danger'}>{userMessage}</p>}
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="phone" className="signup__col--label" style={{ fontWeight: 500 }}>
                                            Enter the new phone number
                                        </label>
                                        <input type="text" onChange={handleChange} className="signup__col--inp form-control" style={{ border: '1px solid #E8EBED' }} />
                                    </div>
                                </div>
                            
                        </div>
                        <div className="modal-footer border-0" style={{
                            paddingBottom: 56
                        }}>
                            <button type="button" className="signup__col--btn" onClick={submitForm} data-bs-dismiss={ closeModal? "modal" : ''}>
                            Apply changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ChangeNumber
