import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios'

// Define types for state and plan details
interface PlanState {
    data: any
    isLoading: boolean
    isError: boolean
}

interface PlanDetails {
    // Define type for plan details
    addons: any
    pCardDetails: any,
    planDetails: any
}

// Initial state
const initialState: PlanState = {
    data: null,
    isLoading: false,
    isError: false
}

// interface Todo {
//     userId: number
//     id: number
//     title: string
//     completed: boolean
// }
// interface TodoState {
//     isLoading: boolean
//     data: Todo[]
//     isError: boolean
// }
// const initialState2: TodoState = {
//     isLoading: false,
//     data: [],
//     isError: false
// }

// Async thunk for submitting plan details
export const submitPlanDetails = createAsyncThunk('submitPlanDetails', async (planDetails: PlanDetails,  { rejectWithValue }) => {
    try {
        const result = await axios
        .post(`subscription-summary`, planDetails, {
            headers: {
                Authorization: "Bearer "+localStorage.getItem('token'),
                Accept: 'application/json'
            }
        })
        .then((response) => response)
        .catch((error) => error.response)
    return result
        // const response: AxiosResponse<any> = await axios.post(`subscription-summary`, planDetails)
        // console.log(response.data)
        // return response.data
    } catch (err: any) {
        return rejectWithValue(err.response.data)
    }
})

// Slice for plan summary
const planSummarySlice = createSlice({
    name: 'plan-summary',
    initialState,
    reducers: {
        setPlans: (state, action: PayloadAction<any>) => {
            state.data = action.payload
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        setError: (state, action: PayloadAction<boolean>) => {
            state.isError = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(submitPlanDetails.pending, (state) => {
            state.isLoading = true
            state.isError = false
        })
        builder.addCase(submitPlanDetails.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
        })
        builder.addCase(submitPlanDetails.rejected, (state) => {
            state.isLoading = false
            state.isError = true
        })
    }
})

// Export actions and reducer
export const { setPlans, setLoading, setError } = planSummarySlice.actions
export default planSummarySlice.reducer
