import React from 'react'

interface RegularBtnProps {
    btnAction?: () => void
    text?: string
    width?: string
    icon?: any
    backgroundColor?: string
    color?: string
    padding?: string
    borderRadius?: string
    borderColor?: string
    btnFontSize?: string
    btnFontWeight?: string
    btnHeight?: string
    btnClassName?: string
}
function RegularBtn2({
    text,
    width,
    icon,
    backgroundColor,
    color,
    borderRadius,
    borderColor,
    btnFontSize,
    btnFontWeight,
    btnHeight,
    btnAction,
    btnClassName,
    padding
}: RegularBtnProps) {
    return (
        <>
            <button
                className={btnClassName}
                style={{
                    height: btnHeight ? btnHeight : '48px',
                    width: width,
                    backgroundColor: backgroundColor,
                    color: color,
                    fontWeight: btnFontWeight,
                    fontSize: btnFontSize,
                    borderRadius: borderRadius,
                    border: borderColor,
                    padding: padding
                }}
                onClick={btnAction}
            >
                <div className="d-flex align-items-center justify-content-center">
                    <span>{text}</span>
                    <span>{icon}</span>
                </div>
            </button>
        </>
    )
}

export default RegularBtn2
