import React from 'react'

interface FAQItem {
    question: string
    answer: string
}
interface FrequentlyAskedQuestionsProps {
    faqs: FAQItem[]
}

const FrequentlyAskedQuestions: React.FC<FrequentlyAskedQuestionsProps> = ({ faqs }) => {
    return (
        <>
            <div className="faqs pt-md-4" id="faqs">
                <div className="container py-5">
                    <div className="row justify-content-center">
                        <div className="faqs--title pb-5 mb-3">Frequently Asked Questions (FAQs)</div>
                        <div className="col-md-8 p-3">
                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                {faqs.map((faq, index) => (
                                    <div key={index} className="p-3 text-start">
                                        <div className="accordion-item border-0 rounded-0 ">
                                            <h2 className="accordion-header border-0 outline-0 shadow-none" id={`flush-heading${index + 1}`}>
                                                <button
                                                    className="accordion-button collapsed border-0 pb-0"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#flush-collapse${index + 1}`}
                                                    aria-expanded="false"
                                                    aria-controls={`flush-collapse${index + 1}`}
                                                >
                                                    <span>{faq.question}</span>
                                                </button>
                                            </h2>
                                            <div
                                                id={`flush-collapse${index + 1}`}
                                                className="accordion-collapse collapse"
                                                aria-labelledby={`flush-heading${index + 1}`}
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">{faq.answer}</div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FrequentlyAskedQuestions
