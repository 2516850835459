import React from 'react'
import OSDownloadPage from './component/OSDownloadPage'

const DownloadAppVersion = () => {
    return (
        <div>
            <OSDownloadPage />
        </div>
    )
}

export default DownloadAppVersion
