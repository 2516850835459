import React from 'react'
import RegularBtn from '../../../components/buttons/RegularBtn'
import { COLORS } from '../../../resources/colors-contants'
import { useNavigate } from 'react-router-dom'
import RegularBtn2 from '../../../components/buttons/RegularBtn2'

function MobileHero() {
    const navigate = useNavigate()
  return (
    <>
       <div className="home">
       <div className="row d-block d-md-none m-0 pb-3 ">
                        <div className="col-md-6 pb-5 ps-4 pe-4">
                            <h3 className="home__col--title ">Digitalising business processes to drive sales both offline & online with our one year free access.</h3>
                            <p className="mb-3 pb-1">Unlock Business Potential, Boost Sales, Maximise Profits, Operate Efficiently Both Offline and Online</p>
                            <RegularBtn2 btnHeight="53px" borderColor='2px solid #2C4A8B' borderRadius="7px" color="white" backgroundColor="#2C4A8B"  text="Download Klakpad App" btnFontSize='16px' btnFontWeight='500' padding='15px 25px' btnAction={()=>navigate('/download')}/>
                        </div>
                        <div
                            className="col-md-6"
                            style={{
                                backgroundImage: 'url(/assets/hero.png)',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                objectFit: 'cover',
                                height: '291.95px',
                                overflow: 'hidden'
                            }}
                        />
                    </div>
       </div>
    </>
  )
}

export default MobileHero
