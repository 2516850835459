import React, { useState } from 'react'
import ProductHero from './productHero/Hero'
import ManageSection from './manageSection/manageSection'
import BlueCardLayout from './otherComponents/blueCardLayout'
import ManageSectionLayout from './manageSection/manageSectionLayout'
import Jason from '../../../assets/images/new_product/jasonStatham.png'
import BulletList from './bulletSection/bulletList'
import takeAction from '../../../assets/images/new_product/takeAction.png'
import showMe from '../../../assets/images/new_product/showMe.png'
// import ProductPlanCardWrapper from './planSection/productPlanCard'
import FrequentlyAskedQuestions from '../../../components/faqs'
import SellaLearn from './sellaLearn/sellaLearn'
import './product.css'
import MobileProductPlanCardWrapper from '../../../components/newPlans/components/mobileProductPlanCard'
import ProductPlanCardWrapper from '../../../components/newPlans/components/productPlanCard'
import { MyContextProvider } from '../../../components/newPlans/context'
import TogglePlanBtns from '../../../components/newPlans/components/toggleBtn'
import { Link } from 'react-router-dom'
// import MobileProductPlanCardWrapper from './planSection/mobileProductPlanCard'

interface CollapsedSections {
    section1: boolean
    section2: boolean
    section3: boolean
}

const Product: React.FC = () => {
    const [amount, setAmount] = useState('')
    const [yearOrMonth, setYearOrMonth] = useState('yearly')

    const [collapsedSections, setCollapsedSections] = useState({
        section1: false,
        section2: false,
        section3: false
    })

    const toggleCollapse = (sectionId: keyof CollapsedSections) => {
        console.log('sectionId', sectionId)
        setCollapsedSections((prevState) => ({
            ...prevState,
            [sectionId]: !prevState[sectionId]
        }))
    }

    const arr1 = [
        {
            head: 'Efficiency Maximisers',
            body: "Our tools are designed to be efficiency experts, making sure everything runs smoothly. It's like having a personal assistant that helps you get things done with maximum efficiency"
        },
        {
            head: 'Cost-Cutting',
            body: 'Say goodbye to unnecessary expenses! Our tools are champions at bringing costs down to the bare minimum, ensuring your business runs efficiently without breaking the bank'
        },
        {
            head: 'Profit-Boosting Magic',
            body: "Picture a tool that works like a profit-boosting magician. By minimising costs, it has the potential to increase your profits by more than 300%. It's like turning a financial trick that brings in extra cash for your business"
        },
        {
            head: 'Follow the money',
            body: 'Organise inventory costs by using reports that provide you with cost and sales information for each item.'
        }
    ]
    const arr2 = [
        {
            head: 'Inventory Management Made Easy',
            body: "Our tools for handling inventory are right at your fingertips. You can effortlessly create your products and specify their measurements. It's like having a super simple way to keep track of what you have in stock without any hassle."
        },
        {
            head: 'Flexible Product Setup',
            body: "Now, you're in control! When you sell items in different ways, like cartons, packs, or units, you can easily set it up. It's like having a tool that lets you decide how many units make up a pack and how many packs fit into a carton. It's like having a customisable system that adapts to the way you sell."
        },
        {
            head: 'Save Time, Sell Smarter',
            body: "Picture a tool that not only saves you time but also makes you a selling pro. Now, you can sell your products in any way you want, and it's as easy as a few clicks. It's like having a shortcut to success without the worry of getting your inventory numbers wrong"
        },
        {
            head: 'Inventory Confidence',
            body: "Gain confidence in your inventory management. Our tools provide a sense of security, ensuring that you're in control of your stock without the stress. It's like having a safety net for your business, so you can rest easy knowing your inventory is in good hands"
        }
    ]
    const arr3 = [
        {
            head: 'Limitless Customer Management',
            body: "Our platform allows you to effortlessly handle an unlimited customer list, creditors, and debtors, all from a single, user-friendly dashboard. It's like having a central hub for managing all your customer interactions and financial transactions."
        },
        {
            head: 'Customer Insights',
            body: "Gain valuable insights into your customers' purchasing habits and buying capacity. Imagine having a tool that helps you understand what your customers love to buy and how much they can spend. It's like having a crystal ball for predicting your customers' needs."
        },
        {
            head: 'Targeted Marketing',
            body: "Take your marketing to the next level! With our platform, you can re-target customers based on their buying habits. It's like having a personalised marketing assistant that tailors your promotions to match each customer's preferences, increasing the chances of repeat business."
        },
        {
            head: 'Streamlined Customer Engagement',
            body: "Simplify your customer engagement strategy. Our dashboard is designed to make it easy for you to connect with customers, manage debts, and build lasting relationships. It's like having a direct line to your customers, ensuring you're always in tune with their needs and preferences."
        }
    ]
    const faqData = [
        { question: 'What is Accounting software?', answer: 'coming soon' },
        { question: 'Why should I choose Klakpad over other accounting softwares?', answer: 'coming soon' },
        { question: 'Is it easy to set up Klakpad application?', answer: 'coming soon' },
        { question: 'Do i need to download Klakpad accounting software?', answer: 'coming soon' }
    ]

    return (
        <div>
            <ProductHero />

            <div className="container py-3 py-md-5" style={{}}>
                <div className="d-block d-md-none">
                    <ManageSection
                        head="Easy, Efficient & Evolutionary"
                        body="Experience seamless processes with user-friendly interfaces, efficiently optimise productivity and stay ahead of the curve with adaptive solutions. With the Klakapd Accounting and Business management software on your phone or mobile device, you can take advantage of smooth accounting systems and processes, monitor your business while on the go, and feel secure about your numbers wherever you are. You can stay connected at all times with Klakpad."
                        display={false}
                        shortIt={true}
                    />
                </div>
                <div className="d-none d-md-block">
                    <ManageSection
                        head="Easy, Efficient & Evolutionary"
                        body="Experience seamless processes with user-friendly interfaces, efficiently optimise productivity and stay ahead of the curve with adaptive solutions. With the Klakapd Accounting and Business management software on your phone or mobile device, you can take advantage of smooth accounting systems and processes, monitor your business while on the go, and feel secure about your numbers wherever you are. You can stay connected at all times with Klakpad."
                        display={false}
                    />
                </div>

                <BlueCardLayout />

                {/* ==== SECTION 1 ==== */}
                <div className="d-block d-md-none">
                    <ManageSectionLayout
                        id="section1"
                        head="Increase Profitability"
                        body="By providing tools that cut down time, wastage and loopholes, cost is already brought down to the minimum which in turn could increase profit by more than 300%"
                        display={true}
                        colapse={collapsedSections['section1']}
                        toggleCollapse={() => toggleCollapse('section1')}
                    />
                    {collapsedSections['section1'] === true && (
                        <div className="row mt-0 mt-md-3">
                            <div className="col mb-4 mb-md-0">
                                <img className="img-custom" src={Jason} />
                            </div>
                            <div className="col">
                                <BulletList data={arr1} />
                            </div>
                        </div>
                    )}
                </div>
                {/* SECTION 2 */}
                <div className="d-none d-md-block">
                    <ManageSectionLayout
                        head="Increase Profitability"
                        body="By providing tools that cut down time, wastage and loopholes, cost is already brought down to the minimum which in turn could increase profit by more than 300%"
                    />
                    <div className="row mt-0 mt-md-3">
                        <div className="col col-6 mb-4 mb-md-0">
                            <img className="img-fluid" src={Jason} />
                        </div>
                        <div className="col col-6">
                            <BulletList data={arr1} />
                        </div>
                    </div>
                </div>
                <hr />
                <div className="d-block d-md-none">
                    <ManageSectionLayout
                        id="section2"
                        head="Manage Your Inventory Like a Pro"
                        body="We have put the tools to manage your inventory at your beck and call. You can now create your products and set their measurements."
                        display={true}
                        colapse={collapsedSections['section2']}
                        toggleCollapse={() => toggleCollapse('section2')}
                    />
                    {collapsedSections['section2'] && (
                        <div className="row" style={{ marginTop: '3rem' }}>
                            <div className="col">
                                <img className="img-custom" src={takeAction} />
                            </div>
                            <div className="col">
                                <BulletList data={arr2} />
                            </div>
                        </div>
                    )}
                </div>
                <div className="d-none d-md-block">
                    <ManageSectionLayout
                        head="Manage Your Inventory Like a Pro"
                        body="We have put the tools to manage your inventory at your beck and call. You can now create your products and set their measurements."
                    />
                    <div className="row" style={{ marginTop: '3rem' }}>
                        <div className="col">
                            <BulletList data={arr2} />
                        </div>
                        <div className="col">
                            <img className="img-fluid" src={takeAction} />
                        </div>
                    </div>
                </div>

                <hr />
                <div className="d-block d-md-none">
                    <ManageSectionLayout
                        id="section3"
                        head="Manage customer list"
                        body="Manage unlimited customer list, creditors & debtors all in one easy dashboard. Understand the customer purchasing habits and buying capacity. Re-target 6 customers base on their buying habit"
                        display={true}
                        colapse={collapsedSections['section3']}
                        toggleCollapse={() => toggleCollapse('section3')}
                    />
                    {collapsedSections['section3'] && (
                        <div className="row" style={{ marginTop: '2rem' }}>
                            <div className="col mb-4 mb-md-0">
                                <img className="img-custom" src={showMe} />
                            </div>
                            <div className="col">
                                <BulletList data={arr3} />
                            </div>
                        </div>
                    )}
                </div>
                <div className="d-none d-md-block">
                    <ManageSectionLayout
                        head="Manage customer list"
                        body="Manage unlimited customer list, creditors & debtors all in one easy dashboard. Understand the customer purchasing habits and buying capacity. Re-target 6 customers base on their buying habit"
                    />
                    <div className="row" style={{ marginTop: '2rem' }}>
                        <div className="col mb-4 mb-md-0">
                            <img className="img-custom" src={showMe} />
                        </div>
                        <div className="col">
                            <BulletList data={arr3} />
                        </div>
                    </div>
                </div>

                <hr />
                <div className="mb-4 d-block d-md-none" style={{ marginTop: '6rem' }}></div>
                <div className="row">
                    <div className="col col-12 col-md-8">
                        <ManageSection
                            head="Select the plan most appropriate for you."
                            body="Sign up for klakpad Sella for Retail to receive a 30-day free trial of Plus. You can select the plan that best suits your needs after the trial."
                            display={false}
                        />
                    </div>
                    <div className="d-none d-md-block col col-md-4 text-end" style={{ fontSize: '18px', fontWeight: '600', paddingTop: '8rem' }}>
                        <Link to="/pricing" style={{ textDecoration: 'none', color: '#2C4A8B' }}>
                            <span className="me-3">Compare Plans and Features</span> <span>&rarr;</span>
                        </Link>
                    </div>
                </div>

                <MyContextProvider>
                    {/* <TogglePlanBtns setAmount={setAmount} setYearOrMonth={setYearOrMonth} /> */}
                    <MobileProductPlanCardWrapper amount={amount} yearOrMonth={yearOrMonth} />
                    <ProductPlanCardWrapper amount={amount} yearOrMonth={yearOrMonth} />
                </MyContextProvider>

                <div className="d-block d-md-none " style={{ fontSize: '16px', fontWeight: '600', paddingTop: '1rem' }}>
                    <Link to="/pricing" style={{ textDecoration: 'none', color: '#2C4A8B' }}>
                        <span className="me-3">Compare Plans and Features</span> <span>&rarr;</span>
                    </Link>
                </div>
                <FrequentlyAskedQuestions faqs={faqData} />
            </div>
            <SellaLearn />
        </div>
    )
}

export default Product
