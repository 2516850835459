import React from 'react'
import FormSection from './FormSection'
import ContactAddressSection from './ContactAddressSection'
import ContactHero from './contactHero'

const SalesTeam = () => {
    return (
        <>
            <ContactHero />
            <FormSection />
            <ContactAddressSection />
        </>
    )
}

export default SalesTeam
