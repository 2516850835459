import React from 'react'
import '../styles/SectionTab.css'

interface sectionTabInterface {
    icon: any
    heading: string
    description: string
}
const SectionTab: React.FC<sectionTabInterface> = ({ icon, heading, description }) => {
    return (
        <div className="p-md-4 p-3 ">
            <img className="mb-4" id="iccon" src={icon} alt="" />
            <h3 className="mb-2" id="tabHead" style={{ color: '#111A24', letterSpacing: '-0.16px' }}>
                {heading}
            </h3>
            <p className="mb-5 desc">{description}</p>
        </div>
    )
}

export default SectionTab
