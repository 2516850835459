import axios from 'axios';

export const postRequest = async( url?: any, data?: any) => {
   const result = await axios.post(url, data)
   .then((response) => response)
   .catch((error) => error.response);
   return result;
}

export const postRequest2 = async(url:any, data:any) => {
   const result = await axios.post(url, data, {
      headers: {
         Authorization: `Bearer ${localStorage.getItem('token')}`,
         'Content-Type': 'application/json',
      }
   }).then((response) => response)
      .catch((error) => error.response)
   return result;
}

