import React from 'react'
import '../styles/FooterTop.css'
import { NavLink } from 'react-router-dom'
import { faFacebookF, faSquareInstagram, faLinkedin, faTiktok, faXTwitter } from '@fortawesome/free-brands-svg-icons'
import moment from 'moment'

const FooterTop: React.FC = () => {
    const icons = [
        { link: 'https://www.instagram.com/klakpad/', name: faSquareInstagram, icon: '/assets/icons/icon4.svg' },
        { link: 'https://www.facebook.com/profile.php?id=61556712811820&sk=about', name: faFacebookF, icon: '/assets/icons/icon2.svg' },
        { link: 'https://www.linkedin.com/in/klakpad-global-limited-0718ba290/', name: faLinkedin, icon: '/assets/icons/icon3.svg' },
        { link: 'https://twitter.com/klakpad', name: faXTwitter, icon: '/assets/icons/icon.svg' },
        { link: 'https://www.tiktok.com/@klakpad?_t=8mL7UkqBLMr&_r=1', name: faTiktok, icon: '/assets/icons/icon5.svg' }
    ]

    const currentYear = moment().format('YYYY')
    return (
        <>
            <div className="footer pt-5 mt-5">
                <div className="container py-5  ">
                    <div className="row ps-4 pe-4 d-md-block d-none ">
                        <div className="d-flex justify-content-between ps-2 pe-2">
                            <div className="footer--why">
                                <NavLink to="/" className="footer--title ">
                                    Why Klakpad
                                </NavLink>
                                <div className="pt-4">
                                    <div className="footer--text">
                                        Klakpad provides tailored solutions to handle the challenging aspects of business operations, allowing you to experience
                                        increased efficiency and the freedom to concentrate on what truly matters to you.
                                    </div>
                                </div>
                            </div>
                            <div className="footer--product ps-3">
                                <NavLink to="/" className="footer--title">
                                    Product
                                </NavLink>
                                <div className="pt-4 ">
                                    <div className="footer--text mb-1">kalakpad Sella</div>
                                    <div className="footer--text mb-1">Hotel management Solution</div>
                                    <div className="footer--text">Restaurant and Lounge</div>
                                </div>
                            </div>
                            <div className="footer--company ps-3">
                                <NavLink to="/" className="footer--title ">
                                    Company
                                </NavLink>

                                <div className="pt-4 ">
                                    <div className="footer--text mb-1">About us</div>
                                    <div className="footer--text mb-1">Testimonials</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row ps-4 pe-4 mt-5 pt-4 d-md-block d-none ">
                        <div className="d-flex justify-content-between ps-2 pe-2">
                            <div className="footer--why ">
                                <NavLink to="/" className="footer--title">
                                    Who we serve
                                </NavLink>
                                <div className="pt-4 ">
                                    <div className="footer--text mb-2">Small and medium businesses.</div>
                                    <div className="footer--text mb-2">Retailers</div>
                                    <div className="footer--text mb-2 ">Hotels</div>
                                    <div className="footer--text mb-2">Property management</div>
                                </div>
                            </div>
                            <div className="footer--product">
                                <NavLink to="/" className="footer--title ps-3">
                                    Contact us
                                </NavLink>
                                <div className="pt-4 ps-3">
                                    <div className="footer--text d-flex align-items-center">
                                        <img src="/assets/icons/phone.svg" className="img-fluid" alt="" width={18} />
                                        <NavLink to="tel:+2348079558667" className="mx-3 footer--text text-decoration-none" target="_top">
                                            +234 707 148 8217 | +234 807 955 8667
                                        </NavLink>
                                    </div>
                                    <div className="footer--text d-flex align-items-center mt-3 mb-3">
                                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                opacity="0.8"
                                                d="M16.125 13.627L11.1429 9.12695M6.85714 9.12695L1.87503 13.627M1.5 5.37695L7.62369 9.66354C8.11957 10.0107 8.36751 10.1842 8.6372 10.2514C8.87542 10.3108 9.12458 10.3108 9.3628 10.2514C9.63249 10.1842 9.88043 10.0107 10.3763 9.66354L16.5 5.37695M5.1 15.127H12.9C14.1601 15.127 14.7902 15.127 15.2715 14.8817C15.6948 14.666 16.039 14.3218 16.2548 13.8984C16.5 13.4171 16.5 12.7871 16.5 11.527V6.72695C16.5 5.46684 16.5 4.83678 16.2548 4.35547C16.039 3.93211 15.6948 3.5879 15.2715 3.37219C14.7902 3.12695 14.1601 3.12695 12.9 3.12695H5.1C3.83988 3.12695 3.20982 3.12695 2.72852 3.37219C2.30516 3.5879 1.96095 3.93211 1.74524 4.35547C1.5 4.83678 1.5 5.46684 1.5 6.72695V11.527C1.5 12.7871 1.5 13.4171 1.74524 13.8984C1.96095 14.3218 2.30516 14.666 2.72852 14.8817C3.20982 15.127 3.83988 15.127 5.1 15.127Z"
                                                stroke="white"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>

                                        <div className="mx-3">contact@Klakpad.com</div>
                                    </div>
                                    <div className="footer--text d-flex align-items-center mt-3 mb-3">
                                        <img src="/assets/icons/location.svg" className="img-fluid" alt="" width={18} />
                                        <span className="mx-3 ">Klakpad Global Inc, USA 913 Miller St, Easton, Pennsylvania 18042 USA</span>
                                    </div>
                                    <div className="footer--text pt-3 mt-2 mb-1">Nigeria address:</div>
                                    <div className="footer--text d-flex align-items-center mt-1 pt-1">
                                        <img src="/assets/icons/location.svg" className="img-fluid" alt="" width={18} />
                                        <span className="mx-3 "> Klakpad Global Limited, Nigeria
                                        No 9, Klakpad building Ire-Akari Estate, Ibadan, Oyo State, Nigeria</span>
                                    </div>
                                </div>
                            </div>
                            <div className="footer--company">
                                <NavLink to="/" className="footer--title ps-3 ">
                                    Follow us
                                </NavLink>

                                <div className="pt-4 ">
                                    {icons.map((icon, i) => (
                                        <a href={icon.link} key={i} target="_blank" className="ps-3  text-light" rel="noopener noreferrer">
                                            <img src={icon.icon} alt="icon" className="img-fluid" width={18} />
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row d-md-block d-none  footer">
                {' '}
                <div className="footer--line " />
            </div>
            <div className="footer">
                <div className="container">
                    <div className="row d-md-block d-none  py-4">
                        <div className="d-flex justify-content-between p-4 m-2">
                            <div className="">
                                <NavLink to="/">
                                    {' '}
                                    <img className="mb-4" src="/assets/icons/klakpad.svg" alt="logo" width={110} />
                                </NavLink>
                                <div className="mt-4 footer--text">
                                    <img src="/assets/icons/@.svg" alt="icon" className="img-fluid " /> {currentYear} Klakpad Global Limited
                                </div>
                            </div>
                            <div className="">
                                <div className="d-flex mt-4 pt-5">
                                    <NavLink to="/terms-and-conditions" className="text-decoration-none mx-2">
                                        <p className="footer--text  pe-lg-5">Terms of Service</p>
                                    </NavLink>
                                    <NavLink to="/privacy-policy" className="text-decoration-none">
                                        <p className="footer--text text-decoration-none  pe-lg-5">Privacy policy.</p>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FooterTop
