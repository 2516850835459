export const COLORS = {
    primary_background: '#2C4A8B',
    secondary_background: '#F5F7FA',
    plan_blue: '#F2FAFD',
    black_color: 'black',
    white_color: 'white',

    // product page
    product_headings: '#111A24',
    product_paragraph: '#60666D'
}
