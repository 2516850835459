import React from 'react'
import BulletSection from './bullet'

interface ArrayList {
    head: string
    body: string
}
interface BulletListProp {
    data: ArrayList[]
}
const BulletList: React.FC<BulletListProp> = ({ data }) => {
    return (
        <div>
            {data.map((dat, index) => (
                <BulletSection key={index} head={dat.head} body={dat.body} />
            ))}
        </div>
    )
}

export default BulletList
