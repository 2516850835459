import React from 'react'
import './style/CurvedBackground.css'

interface SubheaderProp {
    heading: string
    subHeading?: string
}

const SubHeader: React.FC<SubheaderProp> = ({ heading, subHeading }) => {
    return (
        <div>
            <div className="contain">
                <div className="container" id="inner" style={{ color: '#111A24' }}>
                    <h2 id="sub-header-head">{heading}</h2>
                    <small id="sub-header-desc">{subHeading}</small>
                </div>
            </div>
        </div>
    )
}

export default SubHeader
