import React from 'react'
import { Formik, Field, Form, ErrorMessage, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import RegularBtn from '../../../components/buttons/RegularBtn'
import { COLORS } from '../../../resources/colors-contants'
import '../styles/FormSection.css'

interface FormValues {
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    message: string
}

const FormSection: React.FC = () => {
    const validationSchema = Yup.object({
        firstName: Yup.string().required('First Name is required'),
        email: Yup.string().email('Invalid email address').required('Email is required'),
        phoneNumber: Yup.string()
            .matches(/^(0|\+?234)\d{10}$/, 'Invalid phone number') // Adjusted regex for 11-digit Nigerian phone numbers
            .required('Phone number is required'),
        message: Yup.string().required('Message is required')
    })
    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: ''
    }

    const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
        // Handle form submission logic here
        console.log(values)
        setSubmitting(false)
    }

    return (
        <div className="contact py-5" style={{ backgroundColor: '#F5F7FA' }}>
            <div className="container py-5">
                <div className="row justify-content-center">
                    <div className="col-md-9">
                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    <Form className="row">
                        <div className="col-12 col-md-6 mb-3 ">
                            <label className="formik-label mb-1" htmlFor="firstName">
                                First Name
                            </label>
                            <Field className="form-control py-3 shadow-none" type="text" id="firstName" name="firstName" />
                            <ErrorMessage className="formik-label" name="firstName" component="div" />
                        </div>
                        <div className="col-12 col-md-6 mb-3 ">
                            <label className="formik-label mb-1" htmlFor="lastName">
                                Last Name
                            </label>
                            <Field className="form-control py-3 shadow-none" type="text" id="LastName" name="lastName" />
                            <ErrorMessage className="formik-label" name="lastName" component="div" />
                        </div>

                        <div className="col-12 col-md-6 mb-3 ">
                            <label className="formik-label mb-1" htmlFor="email">
                                Email
                            </label>
                            <Field className="form-control py-3 shadow-none" type="email" id="email" name="email" />
                            <ErrorMessage className="formik-label text-red" name="email" component="div" />
                        </div>
                        <div className="col-12 col-md-6 mb-3 ">
                            <label className="formik-label mb-1" htmlFor="phoneNumber">
                                Phone No
                            </label>
                            <Field className="form-control py-3 shadow-none" type="tel" id="phoneNumber" name="phoneNumber" />
                            <ErrorMessage className="formik-label" name="phoneNumber" component="div" />
                        </div>
                        <div className="col-12 mb-4">
                            <label className="formik-label mb-1" htmlFor="message">
                                Message
                            </label>
                            <Field className="form-control py-3 shadow-none" as="textarea" id="message" name="message" rows={6}/>
                            <ErrorMessage className="formik-label" name="message" component="div" />
                        </div>
                        <div className="col-12  mt-2">
                            <button className='contact--btn'>Send your message</button>
                        </div>
                    </Form>
                </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormSection
