import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AllLocations from './main/AllLocations'

const Locations = () => (
    <Routes>
        <Route path="/locations" element={<AllLocations />}></Route>
        <Route path="/single-location-details/:locationId" element={<></>}></Route>
        <Route path="/all-locations" element={<AllLocations />}></Route>
    </Routes>
)

export default Locations
