import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import Cookies from 'js-cookie'
import Dashboard from '../views/Dashboard'
import SignIn from '../views/authentication/signin'
import Signup from '../views/authentication/signup'

const checkAuthenticatedUser = () => {
    const user_token = localStorage.getItem('token' || [])
    return user_token && true
}
const checkEmail = () => {
    const email = localStorage.getItem('email' || [])
    return email && true
}

export const ProtectedRoute = () => {
    const isAuthenticated = checkAuthenticatedUser()
    return isAuthenticated ? <Navigate to="/dashboard" /> : <Navigate to="/login" />
}

export const GaurdSignInPage = () => {
    const isAuthenticated = checkAuthenticatedUser()
    return !isAuthenticated ? <SignIn /> : <Navigate to="/plans-and-billings" />
}

export const GaurdSignupPage = () => {
    const isAuthenticated = checkEmail()
    return !isAuthenticated ? <Signup /> : <Navigate to="/login" />
}
