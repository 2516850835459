import React, {useEffect} from 'react'
import SectionThree from './SectionThree'
import HeroSection from './HeroSection'
import BenefitSection1 from './BenefitSection1'
import rectangle from '../../../assets/images/home/rectangle.png'
import shield from '../../../assets/images/home/shield.png'
import user from '../../../assets/images/home/user.png'
import loyalty from '../../../assets/images/home/men.png'
import growth from '../../../assets/images/home/growth.png'
import imgGrowth from '../../../assets/images/home/lady.png'
import wireless from '../../../assets/images/home/wireless.png'
import wireImg from '../../../assets/images/home/seller.png'
import database from '../../../assets/images/home/database.png'
import dbImg from '../../../assets/images/home/teacher.png'
import GetStartedSection from './GetStartedSection'
import TestimonialSection from './TestimonialSection'
import FrequentlyAskedQuestions from '../../../components/faqs'
import MobileHero from './MobileHero'
import CustomersTestimonies from './CustomersTestimonies'

const Home: React.FC = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }
    useEffect(() => {
        scrollToTop()
    }, [])

    const faqsForHomePage = [
        {
            question: 'Can I track my inventory levels in real-time?',
            answer: 'Yes, our software offers real-time tracking of inventory levels, allowing you to make informed decisions based on the latest data.'
        },
        {
            question: 'Does the software support financial reporting and analysis?',
            answer: 'Absolutely. Our software provides robust financial reporting tools, offering insights into your business performance.'
        },
        {
            question: 'Is the software scalable to accommodate business growth?',
            answer: 'Yes, our solution is designed to scale with your business. You can easily adapt it to meet the growing needs of your enterprise.'
        },
        {
            question: 'Is there customer support available for assistance?',
            answer: 'Yes, we provide dedicated customer support to assist you with any queries or issues you may encounter while using our software.'
        },
        {
            question: 'Can I try the software before committing to a purchase?',
            answer: 'Certainly. We offer a basic plan which is free for you to explore the features and functionalities of our software before making a commitment.'
        },
        {
            question: 'How secure is my data when using your software?',
            answer: 'We prioritize data security. Our software employs robust security measures to protect your sensitive information, ensuring confidentiality and integrity.'
        }
    ]

    return (
        <>
            <HeroSection />
            <MobileHero />
            <BenefitSection1
                head="Reliable Customer-Centric Solutions You Can Trust."
                heading="Security over your Business."
                icon={shield}
                img={rectangle}
                description="Secure your business data and take absolute control over your day-to-day transactions."
            />
            <SectionThree
                description="We provide tools that boost sales, decrease time, waste, and loopholes, while minimising costs, leading to potential revenue growth and maximum profit ."
                heading="Increase revenue and maximise profit."
                icon={growth}
                img={imgGrowth}
            />
            <BenefitSection1
                head=""
                heading="Enhance Customer Satisfaction and Drive Growth"
                icon={user}
                img={loyalty}
                description=" Delight your customer, implement robust loyalty management, effective customer retargeting messaging and email communication to drive repeated sales."
            />
            <SectionThree
                description="Whether your business is online or offline, you are in complete control. work offline and synchronize when you are back online, so no worries about losing data on internet outage."
                heading="Manage your business both online and offline"
                icon={wireless}
                img={wireImg}
            />
            <BenefitSection1
                head=""
                heading="Centralised Data Management"
                icon={database}
                img={dbImg}
                description="Centralise and consolidate data from all your business branches, ensuring a unified source that eliminates duplicates, standardised information, and improves overall data quality. This process is essential for making informed business decisions and provides unrestricted access to the  centralised data."
            />

            {/* <TestimonialSection /> */}
            {/* <CustomersTestimonies /> */}
            <GetStartedSection />
            <FrequentlyAskedQuestions faqs={faqsForHomePage}/>
        </>
    )
}

export default Home
