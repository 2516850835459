import { useSelector } from 'react-redux';
import { PlanDetailsRootState } from '../../store/actions/planDetails';

export const planDetailsStates = () => {
    const subAmount = useSelector((state: PlanDetailsRootState) => state.planDetails.subAmount);
    const couponDiscount = useSelector((state: PlanDetailsRootState) => state.planDetails.couponDiscount);
    const finalTotal = useSelector((state: PlanDetailsRootState) => state.planDetails.finalTotal);
    const topupAmount = useSelector((state: PlanDetailsRootState) => state.planDetails.topupAmount);
    const saved_card = useSelector((state: PlanDetailsRootState) => state.planDetails.saved_card);
    const ordersSummary = useSelector((state: PlanDetailsRootState) => state.planDetails.ordersSummary);
    const payment_method = useSelector((state: PlanDetailsRootState) => state.planDetails.payment_method);
    const plan_id = useSelector((state: PlanDetailsRootState) => state.planDetails.plan_id);
    const planPrice = useSelector((state: PlanDetailsRootState) => state.planDetails.planPrice);
    const coupon = useSelector((state: PlanDetailsRootState) => state.planDetails.coupon);
    const duration = useSelector((state: PlanDetailsRootState) => state.planDetails.duration);
    const location_id = useSelector((state: PlanDetailsRootState) => state.planDetails.location_id);
    const additional_products = useSelector((state: PlanDetailsRootState) => state.planDetails.additional_products);
    const additional_users = useSelector((state: PlanDetailsRootState) => state.planDetails.additional_users);
    const additional_warehouse = useSelector((state: PlanDetailsRootState) => state.planDetails.additional_warehouse);
    const card_type = useSelector((state: PlanDetailsRootState) => state.planDetails.card_type);
    const email = useSelector((state: PlanDetailsRootState) => state.planDetails.email);
    const auth_code = useSelector((state: PlanDetailsRootState) => state.planDetails.auth_code);
    const validation = useSelector((state: PlanDetailsRootState) => state.planDetails.validation);
    const rerender = useSelector((state: PlanDetailsRootState) => state.planDetails.rerender);
    const finalTotals = useSelector((state: PlanDetailsRootState) => state.planDetails.finalTotals);
    const additionalUsers = useSelector((state: PlanDetailsRootState) => state.planDetails.additionalUsers);
    const additionalOutlets = useSelector((state: PlanDetailsRootState) => state.planDetails.additionalOutlets);
    const additionalProducts = useSelector((state: PlanDetailsRootState) => state.planDetails.additionalProducts);
    const name = useSelector((state: PlanDetailsRootState) => state.planDetails.name);
    const loader = useSelector((state: PlanDetailsRootState) => state.planDetails.loader);
    const isOnboarding = useSelector((state: PlanDetailsRootState) => state.planDetails.isOnboarding);
    const self = useSelector((state: PlanDetailsRootState) => state.planDetails.self);
    const agent = useSelector((state: PlanDetailsRootState) => state.planDetails.agent);
    const onboarding = useSelector((state: PlanDetailsRootState) => state.planDetails.onboarding);
    const onboardingData = useSelector((state: PlanDetailsRootState) => state.planDetails.onboardingData);
    
    return {
        planPrice,
        subAmount,
        couponDiscount,
        finalTotal,
        topupAmount,
        saved_card,
        ordersSummary,
        payment_method,
        plan_id,
        coupon,
        duration,
        location_id,
        additional_products,
        additional_users,
        additional_warehouse,
        card_type,
        email,
        auth_code,
        validation,
        rerender,
        finalTotals, 
        additionalUsers, 
        additionalOutlets, 
        additionalProducts,
        name,
        loader,
        isOnboarding,
        self,
        agent,
        onboarding,
        onboardingData
    }
}