import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import '../styles/ChatComponent.css'

interface contactUsProp {
    icon: any
    hea: string
    description: string
}
const ChatUsComponent: React.FC<contactUsProp> = ({ icon, hea, description }) => {
    return (
        <div className='contact'>
            <div className="container">
                <div className="row align-items-center">
                <div>
                <FontAwesomeIcon id="chat-font" icon={icon} style={{ color: '#60666D' }} />
            </div>
            <div>
                <small id="under-icon">{hea}</small>

                <p id="icon-desc">{description}</p>
            </div>
                </div>
            </div>
        </div>
    )
}

export default ChatUsComponent
