import React from 'react'
import { faMessage } from '@fortawesome/free-solid-svg-icons'
import { faPhoneVolume } from '@fortawesome/free-solid-svg-icons'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import ChatUsComponent from './ChatUsComponent'
// import '../styles/ChatComponent.css'

const ContactAddressSection = () => {
    const list = [
        { text: 'Chat us', con: faMessage, description: 'support@klakpad.com' },
        { text: 'Call us', con: faPhoneVolume, description: '090XXXXXXX' },
        { text: 'Visit us', con: faLocationDot, description: 'No 9, Ire-Akari Estate, Ibadan, Oyo State.' }
    ]
    return (
        <div className="py-5">
            <div className="container pt-5 text-center">
                <h3 className="mb-5 contact-head">Get in touch with us </h3>
                <div className="row mb-md-5">
                    {list.map((li, i) => (
                        <div key={i} className="col-12 col-md-4 mb-5 mb-md-0">
                            <ChatUsComponent icon={li.con} hea={li.text} description={li.description} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ContactAddressSection
