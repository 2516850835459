import React, { useEffect, useState } from 'react'
import CardOptions from './cardOptions'
import paymentTypes from '../../resources/paymentMethod.json'
import { useDispatch } from 'react-redux'
import { updatePlanDetailsStates } from '../../store/actions/planDetails'
import { planDetailsStates } from './planDetailsStates'
import { PostRequest } from '../../resources/api-request'
import { PaystackButton } from 'react-paystack';
import { calculateCharges } from './calculatePaystackCharges'

const PayWithCard = ({ planDetails, handleRadioChange, paymentMethodChoices}: any) => {

    
    const [savedCard, setSavedCard] = useState(false);

    const dispatch = useDispatch();
    const {topupAmount=0, email, auth_code, card_type, rerender} = planDetailsStates();
    
    const componentProps = {
        email,
        amount: (topupAmount + calculateCharges(topupAmount)) * 100,
        // metadata: {
        //     name: 'Customer Name',
        //     phone: '080XXXXXXX',
        // },
        publicKey: 'pk_live_56435f881fda43bb730858fd456f3f39931e8ee3',
        // publicKey: 'pk_test_c366d758f39e4aaad479f672946348b5d12a5fde',
        text: "Add Money",
        onSuccess: (reference: any) => {
            // top up wallet on payment successful
            topupWallet(reference.reference);
        },
        onClose: () => console.log("Payment canceled"),
    };

    const topupWallet = async (reference: any='') => {
        
        dispatch(updatePlanDetailsStates({loader: true}));
        const response = await PostRequest("/topup-wallet", {
            amount: topupAmount,
            card_type,
            reference_id: reference,
            email,
            save_card: savedCard ? 1 : 0,
            auth_code
        });

        alert(response.data.message);
        dispatch(updatePlanDetailsStates({loader: false, topupAmount: 0}));
    }



    return (
        <>
            <div className="modal addMoney fade border-0" id="payWithCardModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content  p-3">
                        <div className="modal-header border-0">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <h4 className="ps-3 addMoney--title">Add Money with Debit Card</h4>
                        <div className="modal-body">
                            <div className="row pb-3">
                                <div className="mb-2 addMoney--balance">Wallet Balance: </div>
                                <div className="addMoney--amount">₦{new Intl.NumberFormat().format(planDetails?.walletBalance)}.00</div>
                            </div>
                            <div className="row mt-5 mb-3 ">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="amount" className="addMoney--label mb-2">
                                        Enter amount
                                    </label>
                                    <input
                                        type="text"
                                        className="py-3 addMoney--input bg-transparent form-control shadow-none"
                                        placeholder="₦1000-1,000,000,000"
                                        onChange={(text) => dispatch(updatePlanDetailsStates({topupAmount: parseInt(text.target.value)}))}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <label htmlFor="select" className="addMoney--label mb-2">
                                        Select card
                                    </label>
                                    <div className="card ">
                                        {paymentMethodChoices &&
                                            paymentMethodChoices.map((val: any) => (
                                                <div
                                                    className="d-flex flex-row align-items-center summary__card--payment-options "
                                                    style={{ cursor: 'pointer' }}
                                                    key={val.id}
                                                    onClick={(e: any) => {
                                                        // handleRadioChange(e, val.id, "save_card");
                                                        // setCardType("saved_card");
                                                        dispatch(updatePlanDetailsStates({auth_code: val.authorization_code, card_type: 'saved_card'}))
                                                    }}

                                                >
                                                    <CardOptions handleRadioChange={handleRadioChange} val={val} name="topups" />
                                                </div>
                                            )
                                        )}
                                        <div style={{cursor: 'pointer'}} className="d-flex flex-row mt-3" onClick={(e:any) => handleRadioChange(e, 3, "new_card")}>
                                            <div className="col-10 d-flex mb-3 ps-3" style={{color: '#2C4A8B'}}>
                                                Pay with new card
                                            </div>
                                            <div className="col-2 text-center">
                                                <div className="form-check">
                                                    <input
                                                        className=""
                                                        type="radio"
                                                        name="topups"
                                                        id="topups"
                                                        // onChange={(e: any) => updatePlanDetailsStates({card_type: "new_card", auth_code: ""})}
                                                        // checked={card_type === "new_card" ? true : false}
                                                    />
                                                    <label className="form-check-label" htmlFor="flexRadioDefault1"></label>
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div><br/>
                                    {
                                        card_type == "new_card"
                                        &&
                                        <span>
                                            <input type="checkbox" name="" id="" onClick={()=> {
                                                setSavedCard(!savedCard);
                                            }} checked={savedCard} /> <span className="mx-1">Save card</span>
                                        </span>
                                    }
                                </div>
                            </div>
                            <div className="modal-footer border-0 pb-4 pt-4">
                                <button type="button" className="addMoney--close me-0 " data-bs-dismiss="modal">
                                    Cancel
                                </button>
                                {
                                    card_type === 'saved_card' ?
                                
                                <button onClick={() => {
                                    if(topupAmount < 1) {
                                        alert("Enter an amount");
                                    } else {
                                        topupWallet();
                                    }
                                }} type="button" className="addMoney--addBtn" data-bs-toggle="modal" data-bs-target="#payWithCardModal">
                                    Add money
                                </button>
                               :
                               <PaystackButton className="addMoney--addBtn me-0 px-0" {...componentProps} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PayWithCard
