import React, { useState, useEffect } from 'react'
import FrequentlyAskedQuestions from '../../components/faqs'
import PriceHero from './components/hero'
import PlanCategories from './components/planCategories'
import PlansTable from './components/plansTable'
import { getRequest } from '../../resources/api-request'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'
import { useMyContext } from '../../components/newPlans/context'

const Pricing = () => {
    const [yearOrMonth, setYearOrMonth] = useState<string>('yearly')
            const [amount, setAmount] = useState<number | any>(60000);
            const [page, setPage] = useState<any>()
            const location = useLocation()
    useEffect(() => {
        const getSubscription = async () => {
            const response = await getRequest('subscription-plans');
            setAmount(response?.data?.headers?.[1].amountYear *response?.data?.headers?.[1].discount)
        }

        getSubscription();
    })

    useEffect(() => {
      const params = new URLSearchParams(location.search);
      const getPage = params.get('page');
      setPage(getPage);
    }, [location.search])
    

    
    const faqsForHomePage = [
        {
            question: 'Can I track my inventory levels in real-time?',
            answer: 'Yes, our software offers real-time tracking of inventory levels, allowing you to make informed decisions based on the latest data.'
        },
        {
            question: 'Does the software support financial reporting and analysis?',
            answer: 'Absolutely. Our software provides robust financial reporting tools, offering insights into your business performance.'
        },
        {
            question: 'Is the software scalable to accommodate business growth?',
            answer: 'Yes, our solution is designed to scale with your business. You can easily adapt it to meet the growing needs of your enterprise.'
        },
        {
            question: 'Is there customer support available for assistance?',
            answer: 'Yes, we provide dedicated customer support to assist you with any queries or issues you may encounter while using our software.'
        },
        {
            question: 'Can I try the software before committing to a purchase?',
            answer: 'Certainly. We offer a basic plan which is free for you to explore the features and functionalities of our software before making a commitment.'
        },
        {
            question: 'How secure is my data when using your software?',
            answer: 'We prioritize data security. Our software employs robust security measures to protect your sensitive information, ensuring confidentiality and integrity.'
        }
    ]
    return (
        <>
            <PriceHero amount={amount} setAmount={setAmount} setYearOrMonth={setYearOrMonth} yearOrMonth={yearOrMonth} 
            page={page}/>
            <PlansTable yearOrMonth={yearOrMonth} />
            <PlanCategories />
            <FrequentlyAskedQuestions faqs={faqsForHomePage} />
        </>
    )
}

export default Pricing
