import React from 'react'
import RegularBtn from '../../../components/buttons/RegularBtn'
import { COLORS } from '../../../resources/colors-contants'
import '../styles/GetStartedSection.css'
import { useNavigate } from 'react-router-dom'
import RegularBtn2 from '../../../components/buttons/RegularBtn2'

const GetStartedSection: React.FC = () => {
    const navigate = useNavigate()
    return (
     <div style={{
        paddingTop: '5rem'
     }}>
           <div className="py-5" style={{ backgroundColor: '#F2F5FA' }}>
            <div className="container py-3 ">
                <div className="mx-auto text-center get-started row ">
                    <h2 className="mb-3" id="started-head">
                        Let’s get you started
                    </h2>
                    <p className="mb-5" id="started-desc">
                        To get started, download the desktop app or the mobile app from the IOS or Play Store and sign up by creating your business profile.
                    </p>
                    <div className="mx-auto" id="btn-div">
                    <RegularBtn2 btnHeight="48px" borderColor='2px solid #2C4A8B' borderRadius="7px" color="white" backgroundColor="#2C4A8B"  text="Download Klakpad App" btnFontSize='16px' btnFontWeight='500' padding='10px 25px' btnAction={()=>navigate('/download')}/>
                    </div>
                </div>
            </div>
        </div>
     </div>
    )
}

export default GetStartedSection
