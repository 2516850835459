
export type PlanDetailsType = {
    planPrice?: number;
    subAmount?: number,
    couponDiscount?: number,
    finalTotal?: number,
    topupAmount?: number,
    saved_card?:number,
    ordersSummary?:any,
    payment_method?:any,
    plan_id?:any,
    coupon?:any,
    duration?:any,
    location_id?:any,
    additional_products?:any,
    additional_users?:any,
    additional_warehouse?:any,
    card_type?:any,
    email?:any,
    auth_code?:any,
    validation?: boolean,
    rerender?:boolean,
    finalTotals?: number, 
    additionalUsers?: number, 
    additionalOutlets?: number, 
    additionalProducts?: number, 
    name?: string,
    loader?: boolean,
    isOnboarding?:string,
    self?:number,
    agent?:number,
    onboarding?: number,
    onboardingData?: []
}

export interface PlanDetailsRootState {
    planDetails: {
        planPrice?: number;
        subAmount?: number,
        couponDiscount?: number,
        finalTotal?: number,
        topupAmount?: number,
        saved_card?: number,
        ordersSummary?:any,
        payment_method?:any,
        plan_id?:any,
        coupon?:any,
        duration?:any,
        location_id?:any,
        additional_products?:any,
        additional_users?:any,
        additional_warehouse?:any,
        card_type?:any,
        email?:any,
        auth_code?:any,
        validation?: boolean,
        rerender?:boolean,
        finalTotals?: number, 
        additionalUsers?: number, 
        additionalOutlets?: number, 
        additionalProducts?: number, 
        name?: string,
        loader?: boolean,
        isOnboarding?:string,
        self?:number,
        agent?:number,
        onboarding?: number,
        onboardingData?: []
    }
}
// actionTypes
export const UPDATE_PLANDETAILS_STATES = "UPDATE_PLANDETAILS_STATES";

// actions
export const updatePlanDetailsStates = (planDetailsStates: PlanDetailsType) => ({
  type: UPDATE_PLANDETAILS_STATES,
  payload: planDetailsStates,
});
