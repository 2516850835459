import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { LuDownload } from 'react-icons/lu'
import { useQuery } from 'react-query'
import { getRequest } from '../../../resources/api-request'
import moment from 'moment'
import DownloadMobileView from './downloadCategoriesMobile'
import DownloadTabView from './downloadCategoryTab'

type VersionType = {
    version: string
    description: string
    date: string // Ensure this is in a format that can be parsed by Date, like "YYYY-MM-DD"
    arch: { size: string; bit: string; link: string }[]
}

const sortVersions = (versions: VersionType[]) => {
    return versions.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
}

const DownloadButton = ({ arch }: { arch: { size: string; bit: string; link: string }[] }) => (
    <div className="dropdown mb-3 selectdiv fw-medium text-center">
        <button className="d-flex align-items-center w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <LuDownload className="me-2" fontSize={20} /> Download
        </button>
        <ul className="dropdown-menu ">
            {arch.map((archItem, index) => (
                <li key={index}>
                    <a className="dropdown-item" href={archItem.link} target="_blank" rel="noreferrer">
                        Download ({archItem.bit})bit
                    </a>
                </li>
            ))}
        </ul>
    </div>
)

const VersionTable = ({ versions }: { versions: VersionType[] }) => (
    <>
        {versions.length === 0 ? (
            <h3 className="mt-5 text-center">No downloads available yet</h3>
        ) : (
            <table className="table text-start downloads__table">
                <thead>
                    <tr>
                        <th className="downloads__table--th1" style={{position: 'relative', left: '3px'}} scope="col">Version</th>
                        <th className="downloads__table--th2" scope="col">Size</th>
                        <th className="downloads__table--th3" scope="col">Date released</th>
                        <th style={{ width: '4%' }} className="downloads__table--th" />
                    </tr>
                </thead>
                <tbody>
                    {versions.map((version, index) => (
                        <tr key={index} className="justify-content-center align-items-center">
                            <td className="downloads__table--td">
                                <div className="d-flex flex-column align-items-flex py-3">
                                    <div className="downloads__table--version ">{version.version}</div>
                                    <div className="downloads__table--description">{version.description}</div>
                                </div>
                            </td>
                            <td className="py-2">
                                <div className="d-flex flex-column py-3 text-center">
                                    <div className="downloads__table--version ">&nbsp;</div>
                                    <div className=" downloads__table--text me-5">
                                        {version.arch[0].size ? `${version.arch[0].size}MB` : `${version.arch[1].size}MB`}
                                    </div>
                                </div>
                            </td>
                            <td className="py-2" style={{position: 'relative', left: '20px'}}>
                                <div className="d-flex flex-column py-3 ">
                                    <div className="downloads__table--version ">&nbsp;</div>
                                    <div className=" downloads__table--text ms-4">
                                        {moment(version.date).format('MMMM Do, YYYY')}
                                    </div>
                                </div>
                            </td>
                            <td className="py-0 position-relative" style={{ position: 'relative', right: '3%' }}>
                                <div className="d-flex flex-column pt-4">
                                    <DownloadButton arch={version.arch} />
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )}
    </>
)

const DownloadCategories: React.FC = () => {
    const [activeTab, setActiveTab] = useState<'Windows' | 'Mac' | 'Linux'>('Windows')
    const [selectedDropdownItem, setSelectedDropdownItem] = useState('Retail & Service')
    const { isLoading, isError, data } = useQuery<any>('result', () => getRequest('https://api.klakpad.com/download_process.php'))

    console.log({data});
    
    if (isLoading) {
        return (
            <div className="row align-items-center justify-content-center">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    }

    if (isError) {
        console.log(isError)
        return <div className='text-center'>Encountered an error</div>
    }

    const handleTabClick = (tabId: 'Windows' | 'Mac' | 'Linux') => setActiveTab(tabId)
    const handleDropdownSelect = (event: React.ChangeEvent<HTMLSelectElement>) => setSelectedDropdownItem(event.target.value)

    const versions = {
        Windows: sortVersions(data?.data?.Windows ?? []),
        Mac: sortVersions(data?.data?.Mac ?? []),
        Linux: sortVersions(data?.data?.Linux ?? [])
    }

    return (
        <>
            <div className="row justify-content-center ">
                <div className="col-auto downloads__wrapper pb-4">
                    <select className="form-select shadow-none downloads__wrapper--select downloads__wrapper--toggle" onChange={handleDropdownSelect}>
                        <option className="downloads--item" value="Retail & Service">Klakpad Sella</option>
                        <option className="downloads--item" value="Hotel Management & Solution (HMS)">Klakpad Otel</option>
                        <option className="downloads--item" value="Restaurant & Lounge">Klakpad MenuPad</option>
                    </select>
                </div>
            </div>
            {selectedDropdownItem === 'Retail & Service' ? (
                <div className="downloads py-4">
                    <div className="container">
                        <DownloadMobileView />
                        <DownloadTabView />
                        <div className="row d-none d-lg-block ms-5 me-5 p-0">
                            <ul className="nav ms-5" id="myTabs">
                                {['Windows', 'Mac', 'Linux'].map((tab) => (
                                    <li key={tab} className="nav-item">
                                        <Link
                                            className={`nav-link downloads--link mx-3 ${activeTab === tab ? 'downloads--active' : ''}`}
                                            id={`${tab.toLowerCase()}-tab`}
                                            data-bs-toggle="tab"
                                            to={`#${tab.toLowerCase()}`}
                                            onClick={() => handleTabClick(tab as 'Windows' | 'Mac' | 'Linux')}
                                        >
                                            <div className={`d-flex align-items-center mt-2 pb-3 justify-content-center ${activeTab === tab ? 'downloads--activeNav' : ''}`}
                                                style={{ height: '43px', position: 'relative', right: 20 }}
                                            >
                                                <i className={`fa-brands fa-${tab.toLowerCase()} mx-2`} />
                                                <span className="mx-2">{`${tab} Version`}</span>
                                            </div>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                            <hr style={{ width: '87%', marginLeft: '5%', color: '#E6EBF5' }} className="border" />
                            <div className="mt-3 pt-2 ms-5 me-5">
                                <VersionTable versions={versions[activeTab]} />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="py-5">
                    <p className="text-center">No content available for the selected category.</p>
                </div>
            )}
        </>
    )
}

export default DownloadCategories
