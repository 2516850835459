import React from 'react'
import ManageSection from './manageSection'

interface ManageSectionProp {
    id?: string
    body: string
    head: string
    colapse?: boolean
    display?: boolean
    toggleCollapse?: () => void
}
const ManageSectionLayout: React.FC<ManageSectionProp> = ({ id, body, head, colapse, display, toggleCollapse }) => {
    return (
        <div>
            <div className="row">
                <div className="col col-12 col-md-9">
                    <ManageSection id={id} head={head} body={body} colapse={colapse} display={display} toggleCollapse={toggleCollapse} />
                </div>
            </div>
        </div>
    )
}

export default ManageSectionLayout
