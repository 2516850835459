import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

interface PlanState {
    isError: boolean
    isLoading: boolean
    data: any
}
const initialState: PlanState = {
    isLoading: false,
    data: [],
    isError: false
}

export const fetchPlans = createAsyncThunk('fetchSubscriptionplans', async () => {
    const res = await fetch(`https://klakpad.dev/api/api/subscription-plans`)
    return res?.json()
})

export const submitPlanDetails = createAsyncThunk('submitPlanDetails', async (planDetails: any, { rejectWithValue }) => {
    try {
        const response = await axios.post(`subscription-summary`, planDetails)
        console.log(response.data)

        return response.data
    } catch (err: any) {
        return rejectWithValue(err.response.data)
    }
})
const planSlice = createSlice({
    name: 'plans',
    initialState,
    reducers: {
        setPlans: (state, action: PayloadAction<any>) => {
            state.data = action.payload
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        setError: (state, action: PayloadAction<boolean>) => {
            state.isError = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPlans.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchPlans.fulfilled, (state, action: PayloadAction<any>) => {
            state.isLoading = false
            state.data = action.payload
        })
        builder.addCase(fetchPlans.rejected, (state) => {
            state.isError = true
        })
        // Handling submitPlanDetails async actions
        builder.addCase(submitPlanDetails.pending, (state) => {
            state.isLoading = true
        })
        builder
            .addCase(submitPlanDetails.fulfilled, (state, action: PayloadAction<any>) => {
                state.isLoading = false
                // Here, you could update state.data with the response if needed
                // For example, adding the new plan details to the data array
                state.data = [...state.data, action.payload]
            })
            .addCase(submitPlanDetails.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                // Optionally, handle the error (action.payload would contain the error if rejectWithValue was used)
            })
    }
})
export const { setPlans, setLoading, setError } = planSlice.actions
export default planSlice.reducer
