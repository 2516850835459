import moment from 'moment'
import { format } from 'path'
import React from 'react'
import { convertToCamelCase } from '../../utility/functions'

const CardOptions = ({ handleRadioChange, val, name = 'flexRadioDefault' }: any) => {
    // console.log(val.id, val.selected, val, 'card-options')

    const formatDate = (expMonth: string, expYear: string) => {
        // Extract the last two digits of the year
        const shortYear = expYear.toString().slice(-2)
        // Combine the month and the short year format
        return `${expMonth}/${shortYear}`
    }

    return (
        <>
            {' '}
            {/* className={`card summary__addon--card h-100  ${selected === id ? 'summary__addon--selectedcard' : ''}`} onClick={onChange} */}
            <div className="col-10 d-flex mb-3 ps-3 mt-3 align-items-center">
                {val.card_type == 'visa' ? <img src="/assets/visa.svg" height={40} alt="" /> : ''}
                <div className="px-3 summary--text">
                    <div className='fw-medium'>**** **** **** {val.last_four} </div>
                    <div style={{color: '#888C91'}}>
                        {convertToCamelCase(val.card_type)}.Expiry {formatDate(val.exp_month, val.exp_year)}
                        {/* {val.card_type}.Expiry {moment(val.exp_month ,val.exp_year).format('MM/DD')} */}
                    </div>
                </div>
            </div>
            <div className="col-2 text-center">
                <div className="form-check">
                    <input className="" name={name} type="radio" id={val.id}  onChange={(e) => handleRadioChange(e, val.id, 'saved_card')} />
                </div>
            </div>
        </>
    )
}
export default CardOptions
