import React from 'react'
import Privacy from './component/privacy'

const PrivacyPolicy = () => {
    return (
        <div>
            <Privacy />
        </div>
    )
}

export default PrivacyPolicy
