import React, { useState } from 'react'
const RadioButton = ({ props }: any) => {
    const { val, selected, onChange } = props
    const { name, qty, id } = val

    return (
        <>
            <div className="form-check form-check-inline summary__addon--radio ps-3">
                <input className="" type="radio" name={name} onChange={onChange} id={id} value={qty} checked={selected == id} />
                <label className="form-check-label" htmlFor={`inlineRadio1${qty}`} />
            </div>
        </>
    )
}
export default RadioButton
