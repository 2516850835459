import React from 'react'
import { useParams } from 'react-router-dom'
import DownloadAppCard from './DownloadAppCard'
import SubHeader from '../../../components/subHeader'

interface Version {
    versionId: string
    versionName: string
    link: string
    size: string
    releaseDate: string
    description: string
}

const OSDownloadPage: React.FC = () => {
    const { platform } = useParams<{ platform: string }>();

    const capitalizeFirstLetter = (value: string) => {
        return value.replace(/\b\w/g, (match) => match.toUpperCase());
    }

    const defaultPlatform = 'windows'
    const selectedPlatform = platform || defaultPlatform

    const versions: { [key: string]: Version[] } = {
        windows: [
            {
                versionId: '1.0.8',
                versionName: 'BETA version',
                link: 'www.download.com',
                size: '150.08mb',
                releaseDate: 'November, 08 2023',
                description: 'Omnix offline first beta version --improved bugs fixing, better upgrade process and more seamless'
            },
            {
                versionId: '1.0.7',
                versionName: 'BETA version',
                link: 'www.download.com',
                size: '110.0mb',
                releaseDate: 'June, 08 2023',
                description: 'Omnix offline first beta version --improved bugs fixing, better upgrade process and more seamless'
            },
            {
                versionId: '1.0.6',
                versionName: 'BETA version',
                link: 'www.download.com',
                size: '99.50mb',
                releaseDate: 'January, 12 2023',
                description: 'Omnix offline first beta version --improved bugs fixing, better upgrade process and more seamless'
            }
        ],
        linux: [
            {
                versionId: '1.0.8',
                versionName: 'BETA version',
                link: 'www.download.com',
                size: '150.08mb',
                releaseDate: 'November, 08 2023',
                description: 'Omnix offline first beta version --improved bugs fixing, better upgrade process and more seamless'
            },
            {
                versionId: '1.0.7',
                versionName: 'BETA version',
                link: 'www.download.com',
                size: '110.0mb',
                releaseDate: 'June, 08 2023',
                description: 'Omnix offline first beta version --improved bugs fixing, better upgrade process and more seamless'
            },
            {
                versionId: '1.0.6',
                versionName: 'BETA version',
                link: 'www.download.com',
                size: '99.50mb',
                releaseDate: 'January, 12 2023',
                description: 'Omnix offline first beta version --improved bugs fixing, better upgrade process and more seamless'
            }
        ],
        apple: [
            {
                versionId: '1.0.8',
                versionName: 'BETA version',
                link: 'www.download.com',
                size: '150.08mb',
                releaseDate: 'November, 08 2023',
                description: 'Omnix offline first beta version --improved bugs fixing, better upgrade process and more seamless'
            },
            {
                versionId: '1.0.7',
                versionName: 'BETA version',
                link: 'www.download.com',
                size: '110.0mb',
                releaseDate: 'June, 08 2023',
                description: 'Omnix offline first beta version --improved bugs fixing, better upgrade process and more seamless'
            },
            {
                versionId: '1.0.6',
                versionName: 'BETA version',
                link: 'www.download.com',
                size: '99.50mb',
                releaseDate: 'January, 12 2023',
                description: 'Omnix offline first beta version --improved bugs fixing, better upgrade process and more seamless'
            }
        ]
    }

    return (
        <div>
            <SubHeader
                heading={`Klakpad for ${capitalizeFirstLetter(platform ?? defaultPlatform)} version`}
                subHeading={`Below are our latest Klakpad version that are supported by ${platform} edition`}
            />
            <div style={{ marginTop: '2rem', paddingBottom: '4rem' }}>
                {versions[selectedPlatform]?.map((vers) => (
                    <DownloadAppCard
                        key={vers.versionId}
                        versionId={vers.versionId}
                        versionName={vers.versionName}
                        link={vers.link}
                        size={vers.size}
                        releaseDate={vers.releaseDate}
                        description={vers.description}
                    />
                ))}
            </div>
        </div>
    )
}

export default OSDownloadPage
