import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";


interface Todo {
  userId: number,
  id: number,
  title: string
  completed: boolean
}
interface TodoState  {
  isLoading: boolean;
  data: Todo[];
  isError: boolean;
}
const initialState: TodoState = {
  isLoading: false,
  data: [],
  isError: false,
};
export const fetchTodos = createAsyncThunk("fetchTodos", async () => {
    const res = await fetch(`https://jsonplaceholder.typicode.com/todos`);
    return res?.json();
 });
 const todoSlice = createSlice({
    name: 'todo',
    initialState,
    reducers: {
        setTodo: (state, action:PayloadAction<Todo[]>) =>{
            state.data = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
          state.isLoading = action.payload;
        },
        setError: (state, action: PayloadAction<boolean>) => {
          state.isError = action.payload;
        },
        addTodo: (state, action: PayloadAction<Todo>) => {
          state.data.push(action.payload);
        },
        deleteTodo: (state, action: PayloadAction<number>) => {
        state.data = state.data.filter((todo) => todo.id !== action.payload);
      }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTodos.pending, (state) => {
          state.isLoading = true;
        });
        builder.addCase(fetchTodos.fulfilled, (state, action: PayloadAction<Todo[]>) => {
          state.isLoading = false;
          state.data = action.payload;
        });
        builder.addCase(fetchTodos.rejected, (state) => {
          state.isError = true;
        });
      },
 })

 export const { setTodo, setLoading, setError, addTodo, deleteTodo } = todoSlice.actions;
 export default todoSlice.reducer