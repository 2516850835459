import React from 'react'

import PlanDetails from './plansDetails'
const PlanDetailsPage = () => {
    return (
        <>
            <PlanDetails />
        </>
    )
}
export default PlanDetailsPage
