import React from 'react'
import { COLORS } from '../../../../resources/colors-contants'
import './blueCard.css'

const blueCardStyles = {
    backgroundColor: COLORS.plan_blue,
    paddingTop: '20px',
    paddingBottom: '30px',
    paddingLeft: '20px',
    paddingRight: '20px',
    borderRadius: '10px'
}

const headstyle = {
    color: COLORS.product_headings,
    fontWeight: '600'
}
const desStyle = {
    color: COLORS.product_paragraph,
    fontWeight: '400'
}

interface blueInterface {
    icon: string
    head: string
    description: string
}

const BlueCard: React.FC<blueInterface> = ({ icon, head, description }) => {
    return (
        <div style={blueCardStyles} className="mb-4 mb-md-0">
            <img id="imgIco" src={icon} className="icon mb-4" />
            <p id="colorHead" style={headstyle}>
                {head}
            </p>
            <p id="ColorDesc" style={desStyle}>
                {description}
            </p>
        </div>
    )
}

export default BlueCard
