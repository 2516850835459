import React from 'react'
import SubHeader from '../../components/subHeader'
import OSDownloadCard from './components/OSDownloadCard'
import DownloadCategories from './components/downloadCategories'
import DownloadTabView from './components/downloadCategoryTab'
import MobileDownload from './components/MobileDownload'

const DownloadApp: React.FC = () => {
    return (
        <>
            <div className="downloads pt-md-5 pt-5">
                <div className="container py-5" id="inner" style={{ color: '#111A24' }}>
                    <div className="row align-items-center justify-content-center text-center pt-5">
                        <div className="col-md-10 ">
                            <h2 className="downloads--title">Download Klakpad App</h2>
                            <p className="downloads--text">
                                Explore the possibilities of Klakpad app and download the application for your <br /> Windows, Linux and MacOs
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            
            <DownloadCategories />     
            <MobileDownload />
        </>
    )
}

export default DownloadApp
