import React from 'react'
import FooterTop from './components/FooterTop'
import FooterMobile from './components/FooterMobile'

const Footer: React.FC = () => {
    return (
        <div>
            <FooterTop />
            <FooterMobile />
        </div>
    )
}

export default Footer
