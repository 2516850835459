export const paystackCharges = {
    NGN: {
      local: {
        percentage: 1.5,
        fixed: 100,
        cap: 2000,
        waiver: 2500
      },
      international: {
        percentage: 3.9,
        fixed: 100
      }
    },
    USD: {
      international: {
        percentage: 3.9,
        fixed: 100
      }
    }
  };
  