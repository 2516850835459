import React from 'react'
import { NavLink } from 'react-router-dom'

const Privacy: React.FC = () => {
    const headings = {
        marginTop: '2rem',
        fontWeight: '600',
        fontSize: '25px'
    }
    const paragraphStyle: React.CSSProperties = { textAlign: 'justify' }

    return (
        <div className="container pt-3">
            <NavLink className=" " to="/">
                <img className="img-fluid " height={37} src="/assets/klakpad.svg" alt="logo" />
            </NavLink>
            <h4 style={{ marginTop: '6rem', marginBottom: '3rem' }}>Klakpad Privacy Policy</h4>

            <h6 style={{ marginBottom: '3rem' }}>Last updated February 13, 2024.</h6>
            <p style={paragraphStyle}>
                This is a contract between you and Klakpad Global Limited (herein referred to as the company). Klakpad and other associated products and
                services hereinafter referred to as 'the products' are owned and run by the company.
            </p>
            <p style={paragraphStyle}>
                At the company, we take your privacy seriously. This Privacy Policy describes how we use the information we collect or that you provide to us
                through the products and other electronic communications.
            </p>
            <p style={paragraphStyle}>
                These govern your use of and access to the products. By accessing or using the products or by otherwise accepting the Terms of Use, you consent
                to this Privacy Policy. If you violate or do not agree to these Privacy Policy and the Terms of Use, your access to and use is unauthorized.
            </p>
            <p style={paragraphStyle}>
                These provisions also apply to every service or action incidental to the performance or functionalities of the Platform. The products is a suite
                of websites, applications, software and hardware tools, third party integrations, plugins through which the company delivers her products and
                services to you.
            </p>

            <h6 style={headings}>Consent</h6>
            <p style={paragraphStyle}>
                By using the products and agreeing to the Terms of Use which this Privacy policy is part of, you have consented to the taking, processing,
                storage, transfer, sharing of your data according to the Nigerian Data Protection Regulations, 2023 and all other data protection regulations in
                all territories where you access the products. By using and agreeing to the terms and conditions which this Privacy policy is part of, you have
                accepted that the taking, processing, storage, transfer and sharing of your data is necessary for the performance of the contract between you
                and the company according to the Nigerian Data Protection Regulation, 2023, and all other data protection regulations in all territories in
                which you access the products.
            </p>

            <h6 style={headings}>Information We Collect</h6>
            <p style={paragraphStyle}>
                We collect information to deliver the products and services you request, to help improve your experience, and to support our business functions.
            </p>
            <p style={paragraphStyle}>We do not sell your data.</p>
            <p style={paragraphStyle}>
                We may collect the following categories of personal information. Not all categories may be collected about every individual:
            </p>
            <ul>
                <li>Personal identifiers, such as name and address</li>
                <li>Device and online identifiers and related information, such as telephone number and email address</li>
                <li>Internet, application, and network activity, such as cookie IDs and browser visits</li>
                <li>Demographic information, such as age and date of birth</li>
                <li>Financial information, such as credit and debit card numbers and claims information, bank accounts and verification numbers</li>
                <li>Location information, such as geo-location information</li>
                <li>Audio, visual, and other sensory information, such as audio and video recordings</li>
                <li>Employment information, such as occupation, title, licenses, and professional memberships</li>
                <li>Education information, such as degree and schooling information</li>
            </ul>
            <h6 style={headings}>How We Collect Personal Information</h6>
            <p style={paragraphStyle}>We collect personal information about you in two different ways:</p>
            <ol>
                <li>Information you provide directly to us</li>
                <li>Information we collect from others</li>
            </ol>

            <h6 style={headings}>Information You Provide Directly to Us</h6>
            <p style={paragraphStyle}>
                You provide information to us when you interact with the products including when you join our mailing list, create an account, subscribe to a
                plan, contact us (via email, telephone, or otherwise), respond to a survey or questionnaire, or enter a contest, sweepstakes, or other promotion
                sponsored by us.
            </p>
            <p style={paragraphStyle}>
                When you interact with the company using an external social network (for example, your Facebook account), we may collect your name and other
                details from your social network profile and account. Please note that when interacting with the products from a third party social network, in
                addition to our Terms of Use and Privacy Notice, you are bound by the terms of use and privacy policies of those websites.
            </p>
            <p style={paragraphStyle}>
                When you use the products, we and third parties may collect certain information by automated means, using technologies such as cookies, Web
                server logs, Web beacons, JavaScript, and components of our apps. We may tie this information to personal information about you that we collect
                from other sources or that you provide to us.
            </p>
            <p style={paragraphStyle}>
                We may share your information with third parties for purposes of ads and operations. In such situations, your data shall be anonymized and shall
                not be traced back to you.
            </p>

            <h6 style={headings}>Cookies</h6>
            <p style={paragraphStyle}>
                Cookies are files that websites send to your computer or other Internet-connected device to uniquely identify your browser or to store
                information or settings on your device. Without cookies, you may not be able to use all the features of The products.The products may use HTTP
                cookies, HTML5 cookies, Flash cookies, and other types of local storage (such as browser-based or plugin-based local storage). Your browser may
                allow you to be notified when you receive certain types of cookies and how to restrict or disable certain cookies.
            </p>
            {/* Add content here  */}
            {/* <h6 style={headings}>Web Server Logs and Web Beacons</h6> */}
            {/* Add content here  */}
            <p style={paragraphStyle}>
                Web server logs and Web Beacons Web server logs may collect information about your device, operating system, browser, domain, and other system
                settings, such as the language your system uses and the country and time zone where your device is located; the address of the Web page that
                referred you to The products; the IP address of the device you use to connect to the Internet; and other information about your interaction with
                the products, such as which pages you visit. To control which Web servers collect information by automated means, we may place small electronic
                tags called Web beacons on our Web pages and in our emails, which are files that link Web pages to particular Web servers and their cookies.
                Again, without web server logs and web beacons, you may not be able to use all the features of the products
            </p>

            <h6 style={headings}>JavaScript</h6>
            {/* Add content here */}
            <p style={paragraphStyle}>
                We or third parties also may send instructions to your device using JavaScript or other computer languages to store or gather the sorts of
                information described above and other details about your interactions with The products.
            </p>
            <p style={paragraphStyle}>
                Mobile Applications, Device Identifiers and SDKs.When you install a mobile app that we provide, we may use or work with partners and service
                providers who use mobile “SDKs” or similar code, in order to collect information such as device identifiers, mobile advertising identifiers
                (e.g., IDFAs and Android Advertising IDs), IP addresses, Internet connection information, as well as details about your device (such as device
                type), mobile browser or operating system, or about how you interact with our apps or services. A mobile SDK is in effect the mobile app version
                of a pixel tag or beacon. The SDK is computer code that app developers can include in their apps to enable ads to be shown, data to be
                collected, and related services to be implemented. We may use this technology, for instance, to analyze how our customers use our applications
                and make purchases, or to deliver or measure certain advertising through mobile applications and browsers based on information associated with
                your mobile device. These SDKs (or other, similar mobile applications and code) may collect mobile identifiers or geolocation information, which
                may be precise, GPS-level information. Information We Receive from Other Sources.We receive information about you from other sources to help us
                correct or supplement our records, improve the quality or personalization of our services and marketing to you, and prevent or detect fraud.
            </p>

            <h6 style={headings}>How We Use Your Information</h6>
            <p style={paragraphStyle}>
                We may use the information you provide directly to us and information we collect about you for various purposes, including to:
            </p>
            <ul>
                <li>To fulfill your requests for services and provide customer service</li>
                <li>To create and maintain your account</li>
                <li>To conduct auditing and monitoring of transactions and engagement</li>
                <li>To conduct marketing, personalization, and third-party advertising</li>
                <li>To protect the security and integrity of our websites, mobile services, and our business, and help prevent fraud</li>
                <li>To update our operational and technical</li>
                <li>To conduct business analysis, such as analytics, projections, identifying areas for operational improvement</li>
                <li>To conduct research and development</li>
                <li>To fulfill our legal/regulatory function or obligations such as Know Your Customer (KYC) and Anti Money Laundering (AML) requirements</li>
            </ul>

            <p style={paragraphStyle}>
                We use third-party Web analytics services, such as those of Google Analytics, to help us analyze how users use The products. These service
                providers use cookies and other automated technologies to collect information about you when you visit The products.
            </p>
            <p style={paragraphStyle}>
                The ad services we use to collect information from you may track your online activities over time and across websites. If we use a third party
                to collect this information, they may use this information to show you advertisements for The company or others that are tailored to your
                individual interests or characteristics and/or based on prior visits to our Sites. We and third-party vendors may use these cookies together,
                and may combine them with other data we collect from you, (1) to inform, optimize, and serve ads (including ads based on past visits to The
                products) and (2) to report on our ad impressions, other uses of ad services, and interactions with these ad impressions and ad services
                (including how they are related to visits to the products).
            </p>
            <h6 style={headings}>Do Not Track</h6>
            <p style={paragraphStyle}>
                Do Not Track is a voluntary program among Internet advertisers that is intended to give users the ability to opt out of behavioral targeting and
                advertising. At this time, we do not respond to “do not track” settings.
            </p>

            <h6 style={headings}>How We Share Your Information</h6>
            <p style={paragraphStyle}>
                All of the categories of personal information that we collect could be shared with other companies, including those within our corporate family,
                for a business purpose. We may share the information we collect or you provide with our service providers as well as with joint marketing
                partners, for any of the purposes set forth in this Privacy Notice.
            </p>
            <p style={paragraphStyle}>
                For instance, we may share the information we collect with service providers that provide hosting services, customer and technical support,
                marketing, billing, payment, or email services, security, anti-fraud or other operational support.
            </p>
            <p style={paragraphStyle}>
                In addition, if you sign up for an account by clicking on an affiliate or referral link (e.g., on a social media site or elsewhere online)
                served by someone who has identified you as a contact of theirs, we may share your personal information with that contact. We may also inform
                people who identify you as their contact whether or not you have a company account, e.g., to permit them to serve as your company referral.
            </p>
            <p style={paragraphStyle}>
                We may share aggregated, anonymized, or de-identified personal information with any third party, without any restriction, including through the
                use of cookies and other automated technologies.
            </p>

            <h6 style={headings}>Legal Right to Disclose Personal Data</h6>
            <p style={paragraphStyle}>Subject to applicable law, we may disclose your personal information:</p>
            <ol type="a">
                <li>if we are required to do so by law, regulation, court order or legal process</li>
                <li>when we believe disclosure would be appropriate in response to governmental or regulatory requests</li>
                <li>when we believe disclosure is necessary or appropriate to prevent physical, financial, or other harm, injury, or loss</li>
                <li>in connection with an investigation of unlawful activity, and for fraud detection and prevention</li>
            </ol>

            <h6 style={headings}>Transfer of Data</h6>
            <ol type="a">
                <li>
                    We may transfer personal information we have about you to a buyer or other successor in the event we sell or transfer all or a portion of
                    our business or assets (such as in connection with a merger, acquisition, reorganization, bankruptcy, dissolution, or liquidation), or take
                    steps in anticipation of such a transaction.
                </li>
                <li>
                    Third parties may collect personal information about your online activities over time and across different websites when you use The
                    products. For example, third-party providers of certain embedded content and tools on The products (such as social network plug-ins) may
                    collect personal information directly from you in connection with your interaction with such content and tools. Such providers’ use of the
                    information is subject to their own privacy policies, which we recommend you review. We are not responsible for such providers’ privacy
                    practices, and those practices are not covered by this Privacy Notice.
                </li>
            </ol>

            <h6 style={headings}>Your Choices</h6>
            <p style={paragraphStyle}>
                If you would like to update or modify your personal information, you may contact us as described in the How To Contact Us section below or,
                where applicable on the products, you may make these choices directly by logging into your account. You may unsubscribe from any of our
                promotional emails by clicking on the “unsubscribe” link at the bottom of any promotional email you receive from us.
            </p>

            <h6 style={headings}>Information Security</h6>
            <p style={paragraphStyle}>
                We take many steps to protect your information, but we can’t promise that our security program will eliminate all security risks. When you
                provide information to us, you do so at your own risk.
            </p>
            <p style={paragraphStyle}>But we do ask you to please help protect your information. There are a number of things you can do, such as:</p>
            <ol>
                <li>
                    Using a unique password for the products that never has or will be used anywhere else - please think up a new one that you never used
                    before, and please only use that password with us;
                </li>
                <li>Protecting the confidentiality of your login information- don’t give your username or password to anyone, anywhere, anytime;</li>
                <li>
                    Accessing the products only from devices that have an up-to-date operating system and up-to-date security software (this makes the products
                    work better anyway); and
                </li>
                <li>
                    Notifying us of any suspected unauthorized activity by emailing us at <a href="mailto:contact@klakpad.com">contact@klakpad.com</a>.
                </li>
            </ol>
            <p style={paragraphStyle}>Taking these steps will not guarantee security but may help lower the risk or impact of certain security threats.</p>

            <h6 style={headings}>Links to Other Websites and Online Services</h6>
            <p style={paragraphStyle}>
                The products may provide links to other third-party websites and online services, such as on The products or third-party service providers that
                help The products operate. Linked websites and services may have their own privacy policies, which we strongly suggest you review. We are not
                responsible for the content or use of any third-party websites or services or privacy or security practices.
            </p>

            <h6 style={headings}>Information Request</h6>
            <ol>
                <li>
                    <p style={paragraphStyle}>
                        You can ask us what personal information we have about you, including a list of categories of your personal information that we have
                        sold and a list of categories of your personal information that we have shared with another company for a business purpose.
                    </p>
                    <p style={paragraphStyle}>More information on each of these requests is below.</p>
                    <p style={paragraphStyle}>
                        "What personal information do you collect about me?" If you make this request, we will return to you (to the extent required by the
                        law):
                    </p>
                    <ul>
                        <li>The categories of personal information we have collected about you.</li>
                        <li>The categories of sources from which we collect your personal information.</li>
                        <li>The business or commercial purpose for collecting or selling your personal information.</li>
                        <li>The categories of third parties with whom we share personal information.</li>
                        <li>The specific pieces of personal information we have collected about you.</li>
                    </ul>
                    <p style={paragraphStyle}>
                        You can ask us to provide you with this information up to two times in a rolling twelve-month period. When you make this request, the
                        information provided may be limited to personal information we collected about you in the previous 12 months.
                    </p>
                </li>
                <li>
                    <p style={paragraphStyle}>
                        Delete My Personal Information: You have the right to ask that we delete your personal information. Once we receive a request, we will
                        delete the personal information (to the extent required by law) we hold about you as of the date of your request from our records and
                        direct any service providers to do the same. In some cases, deletion may be accomplished through de-identification of the information.
                        If you choose to delete your personal information, you may not be able to use certain website or in-store functions that require your
                        personal information to operate. Deleting your personal information will not cancel memberships you have purchased.
                    </p>
                </li>
            </ol>
            <h6 style={headings}>Storage</h6>
            <p style={paragraphStyle}>
                Your information is stored on a Cloud Server in London. The location of your data storage may change based on the technical and business
                requirements. The company commits to the privacy and security of your data at all times. By using the products and agreeing to the terms of use,
                you consent that this is necessary for the performance of this contract as local alternatives are not sufficient.
            </p>
            <p style={paragraphStyle}>
                Our cloud service partners observe the strictest data protection regime under the European General Data Protection Regulation, Nigerian Data
                Protection Regulation, and other data protection regulations in any territory in which you are accessing the products.
            </p>
            <p style={paragraphStyle}>
                Where necessary to share your data with third parties as explained above, your data might be stored in the data warehouse of such parties
                anywhere in the world. We take great care to ensure our third-party partners or service providers provide the best data protection practices.
                However, we cannot guarantee this. We would not be liable in the case of breach of their Security infrastructure exposes your data.
            </p>

            <h6 style={headings}>Updates to Our Privacy Policy</h6>
            <p style={paragraphStyle}>
                We may update this Privacy Notice periodically to reflect changes in our practices or relevant laws as we grow. To notify you of any changes
                that we make, we will post the updated version.
            </p>

            <h6 style={headings}>Contact Us:</h6>
            <p>
                Mail To: <a href="mailto:contact@klakpad.com">contact@klakpad.com</a>
            </p>
            <p>Call: +234 806 624 2995</p>
            <p>Visit: No 9, Klakpad building Ire-Akari Estate, Ibadan, Oyo State, Nigeria.</p>
        </div>
    )
}

export default Privacy
