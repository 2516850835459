import React from 'react'
import PlayStore from '../../../assets/images/download/playStore.svg'
import '../styles/MobileDownload.css'
import { NavLink } from 'react-router-dom'

const MobileDownload: React.FC = () => {
    return (
        <div className="container d-lg-block d-none py-4 " >
            <div className="d-flex justify-content-center my-4 mb-4" id="mobile-head" style={{ color: '#60666D' }}>
                Klakpad app is also available on:
            </div>
            <div className=" d-flex justify-content-center pb-3">
                <NavLink to="https://apps.apple.com/us/app/klakpad-sella/id6504258004" target='_blank' className="d-block" style={{position: 'relative', left: '25px'}}>
                    <img id="playS" src="/assets/icons/apple-logo.svg" />
                </NavLink>
                <NavLink to="https://play.google.com/store/apps/details?id=com.klakpad.klakpad" target='_blank' className="d-block" style={{position: 'relative', right: '25px'}}>
                    <img id="playS" src={PlayStore} />
                </NavLink >
            </div>
        </div>
    )
}

export default MobileDownload
