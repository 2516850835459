import React from 'react'
import BlueCard from './blueCard'
import './blueCardLayout.css'
import { COLORS } from '../../../../resources/colors-contants'
import folderCheck from '../../../../assets/images/new_product/folder-check.svg'
import Sales from '../../../../assets/images/new_product/Icon.svg'
import lineChart from '../../../../assets/images/new_product/line-chart-up-02.svg'

const colDiv: React.CSSProperties = {
    flex: 1,
    backgroundColor: COLORS.plan_blue,
    margin: '10px',
    padding: '20px',
    boxSizing: 'border-box',
    borderRadius: '5px'
}

const BlueCardLayout: React.FC = () => {
    const arr = [
        {
            head: 'Simple Management',
            description: 'Start in a matter of minutes. Sync and manage your stock, then immediately begin selling.',
            icon: folderCheck
        },
        {
            head: 'Track your Sales',
            description:
                "Klakpad Sella allows you to monitor your sales on a daily, weekly, and monthly basis, enabling you to track your company's success and support its growth.",
            icon: Sales
        },
        {
            head: 'Keep Growing',
            description: 'Cloud-based software and integrations that are adaptable and support all of your desired business growth strategies.',
            icon: lineChart
        }
    ]
    return (
        <div className="row" id="rowLay">
            {arr.map((data, index) => (
                <>
                    <div key={index} className="col col-12 col-md-4 d-none d-md-block" style={colDiv}>
                        <BlueCard head={data.head} description={data.description} icon={data.icon} />
                    </div>
                    <div key={index + 5} className="col col-12 col-md-4 d-block d-md-none">
                        <BlueCard head={data.head} description={data.description} icon={data.icon} />
                    </div>
                </>
            ))}
        </div>
    )
}

export default BlueCardLayout
